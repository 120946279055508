// el-input 只能输入数字
export const vNumber = {
  beforeMount(el, binding) {
    const inputEl = el.querySelector('input');
    const decimalPlaces = binding.value || 0;

    inputEl.addEventListener('input', () => {
      let value = inputEl.value;

      // 移除非数字和非小数点字符
      value = value.replace(/[^0-9.]/g, '');
      // 只保留一个小数点
      value = value.replace(/(\..*?)\..*/g, '$1');

      if (decimalPlaces > 0) {
        // 保留指定的小数位数
        const regex = new RegExp(`^(\\d*\\.?\\d{0,${decimalPlaces}}).*$`);
        value = value.replace(regex, '$1');
      } else {
        // 保留整数部分
        value = value.replace(/\..*$/, '');
      }

      inputEl.value = value;
      // 触发 input 事件以更新 v-model 绑定的值
      el.dispatchEvent(new Event('input'));
    });
  }
};

