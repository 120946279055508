import Cookies from 'js-cookie';
// Element Plus 语言包
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import en from 'element-plus/es/locale/lang/en';
import { defineStore } from 'pinia';
const useAppStore = defineStore('app', {
  state: () => ({
    language: Cookies.get('language') || 'en',
    locale: Cookies.get('language') === 'zhCN' ? zhCn : en,
    previousRoute: '', // 上一个路由
  }),

  actions: {
    changeLanguage(language) {
      this.language = language;
      this.locale = language === 'zhCN' ? zhCn : en;
      localStorage.setItem('language', language);
      Cookies.set('language', language);
    },
    setPreviousRoute(route) {
      this.previousRoute = route;
    },
  },
});

export default useAppStore;
