import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '../router/index';
import Cookies from 'js-cookie';
// 创建 Axios 实例
export const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 50000, // 请求超时时间
});
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

const getLanguage = (lang) => {
  switch (lang) {
    case 'zhCN':
      return 'zh_CN';
    case 'en':
      return 'en_US';
  }
};

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以对请求配置进行修改
    // 添加请求头、请求参数处理等
    const token = JSON.parse(localStorage.getItem('loginIfo'))?.accessToken;
    config.headers['language'] = getLanguage(Cookies.get('language') || 'en');
    if (token) {
      config.headers['mall-token'] = token;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    console.error(error);
    return Promise.reject(error);
  },
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据进行处理
    const data = response.data;
    message(data);
    return data;
  },
  (error) => {
    // 响应错误处理
    message(error.response.data);
  },
);

/**
 * 状态码处理 302 200
 * @param {*} data
 * @returns
 */
const message = (data) => {
  if (!data.code) {
    return false;
  }
  switch (data.code) {
    case 302:
      // ElMessage.error(data.msg);
      localStorage.removeItem('loginIfo');
      localStorage.removeItem('userInfo');
      router.push({
        path: '/login',
      });
      break;
    case 200:
      break;
    default:
      ElMessage.error(data.msg);
      break;
  }
};
