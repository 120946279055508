<script setup>
import { useRoute, useRouter } from 'vue-router';
import { watch } from 'vue';
import { SocialAccountAuthorization } from '@/server/api/auth.js';
import useAppStore from '@/store/modules/app';
const appStore = useAppStore();
const route = useRoute();
const router = useRouter();

/**
 * 监听全局路由变化
 */
// watch(route, (to, from) => {
//   // 路由变化时执行的逻辑
//   const params = getQueryParams();
//   const [state, code] = Object.values(params);
//   const routes = from.fullPath;
//   if (params.code != undefined) {
//     if (state && code) {
//       accountAuthorization(state, code, routes);
//     }
//   }
// });

router.beforeEach((to, from, next) => {
  appStore.setPreviousRoute(from);
  next();
});

/**
 * 社交账号sso登录
 */
const accountAuthorization = (state, code, routes) => {
  const type = localStorage.getItem('loginType') || '';
  const obj = {
    code: code,
    scope: 'all',
    state: state,
    type: type,
  };
  SocialAccountAuthorization(obj).then((res) => {
    if (res.code === 200) {
      window.localStorage.setItem('loginIfo', JSON.stringify(res.data));
      location.reload();
      router.push({ path: routes });
    }
  });
};

/**
 * 获取url参数
 */
function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.href);
  const params = {};
  for (const [key, value] of urlParams) {
    params[key] = value;
  }
  return params;
}
</script>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style>
.grecaptcha-badge {
  display: none !important;
}
</style>
