import { createI18n } from 'vue-i18n';
import en from '@/locales/en';
import zhCN from '@/locales/zh';
import gna from '@/locales/gna';
import Cookies from 'js-cookie';

const i18n = createI18n({
  legacy: false,
  locale: Cookies.get('language') || 'en', // 初始化显示英文
  messages: { en, zhCN, gna }, // 这里就是你有几种语言，对象里面就有几个
});

export default function (app) {
  app.use(i18n);
}
