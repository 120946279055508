import { computed, createApp } from 'vue';
import './style.css';
// import store from '@/store';
import 'element-plus/dist/index.css';
import '@/assets/styles/variables.css';
import '@/assets/styles/index.scss';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import App from './App.vue';
// 国际化
import i18n from '@/plugins/i18n';
import router from '@/router/index';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { createPinia } from 'pinia';
import images from './utils/geImg';
import { vNumber } from '@/directives/index.js'; // 假设你的自定义指令文件叫做 directives.js
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Cookies from 'js-cookie';
const app = createApp(App);
const pinia = createPinia();

// pinia.use(piniaPluginPersistedstate);
// 使用element-plus 并且设置全局的大小
app.provide('images', images);
app.directive('number', vNumber);
app.use(createPinia());
app.use(i18n);
app.use(router);
// app.use(store);
app.use(pinia);
app.mount('#app');
import useAppStore from '@/store/modules/app';
const appStore = useAppStore();
app.use(ElementPlus, {
  locale: appStore.locale,
});
