// 项目路由配置
export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('/src/views/login/index.vue'),
    meta: {
      title: '登录',
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('/src/views/register/index.vue'),
    meta: {
      title: '注册',
      hidden: true,
    },
  },
  {
    path: '/user-agreement',
    name: 'user-agreement',
    children: [{
      path: 'zh_CN',
      component: () => import('/src/views/userAgreement/zh_CN.vue'),
    },{
      path: 'en_US',
      component: () => import('/src/views/userAgreement/en_US.vue'),
    }],
    redirect: '/user-agreement/zh_CN',
    meta: {
      title: '用户协议',
      hidden: true,
    },
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('/src/views/home/index.vue'),
    name: 'home',
    meta: {
      title: 'menu.home',
    },
  },
  {
    path: '/pricing',
    meta: {
      title: 'menu.smartQuote',
    },
    children: [
      {
        path: 'smt',
        component: () => import('/src/views/pricing/smt/index.vue'),
        name: 'smt',
        meta: {
          title: 'SMT',
        },
      },
      {
        path: 'pcb',
        component: () => import('/src/views/pricing/pcb/index.vue'),
        name: 'pcb',
        meta: {
          title: 'PCB',
        },
      },
      {
        path: 'bom',
        component: () => import('/src/views/pricing/bom/index.vue'),
        name: 'bom',
        meta: {
          title: 'BOM',
        },
      },
    ],
  },
  {
    path: '/settlement',
    name: 'settlement',
    component: () => import('/src/views/settlement/index.vue'),
    meta: {
      title: '结算页面',
      hidden: true,
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('/src/views/order/payment/index.vue'),
    meta: {
      title: '订单结算(支付)',
      hidden: true,
    },
  },

  // {
  //   path: '/smtDetail',
  //   name: 'smtDetail',
  //   component: () => import('/src/views/order/detail/smtDetail.vue'),
  //   meta: {
  //     title: 'smt详情',
  //     hidden: true,
  //   },
  // },
  {
    path: '/order',
    name: 'order',
    component: () => import('/src/views/order/index.vue'),
    meta: {
      title: '订单管理',
      hidden: true,
    },
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('/src/views/order/detail/index.vue'),
    meta: {
      title: 'smt详情',
      hidden: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'ErrorPage',
    meta: {
      title: '错误页面',
      hidden: true,
    },
    children: [
      {
        path: '404',
        component: () => import('/src/views/error/404.vue'),
        name: '404',
        meta: {
          title: '404',
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/quotation',
    name: 'quotation',
    component: () => import('/src/views/quotation/index.vue'),
    meta: {
      title: '配单在线报价',
      hidden: true,
    },
  },
  {
    path: '/confirm-mail',
    name: 'confirm-mail',
    component: () => import('/src/views/order/confirmMail.vue'),
    meta: {
      title: '确认元器件邮寄清单',
      hidden: true,
    },
  },
  {
    path: '/PaymentStatus',
    name: 'PaymentStatus',
    component: () => import('/src/views/settlement/components/PaymentStatus.vue'),
  },
];

// 项目外链
export const outerLinkList = [
  {
    name: 'Capabilities',
    meta: {
      title: 'home.capabilities',
    },
    children: [
      {
        path: 'https://www.pcbaaa.com/capabilities/design-and-engineering',
        name: 'DESIGN AND ENGINEERING',
        meta: {
          title: 'home.designAndEngineerings',
          isLink: true,
        },
      },
      {
        path: 'https://www.pcbaaa.com/capabilities/oem-odm-service',
        name: 'OEM/ODM SERVICE',
        meta: {
          title: 'home.ODMSevice',
          isLink: true,
        },
      },
      {
        path: 'https://www.pcbaaa.com/capabilities/logistics-and-fulfillment',
        name: 'LOGISTICS AND FULFILLMENT',
        meta: {
          title: 'home.logisticsAndFulfillment',
          isLink: true,
        },
      },
      {
        path: 'https://www.pcbaaa.com/capabilities/after-market-services',
        name: 'AFTER SALES SERVICES',
        meta: {
          title: 'home.afterSalesServices',
          isLink: true,
        },
      },
      {
        path: 'https://www.pcbaaa.com/capabilities/supply-chain-services',
        name: 'SUPPLY CHAIN SERVICES',
        meta: {
          title: 'home.supplyChainServices',
          isLink: true,
        },
      },
      {
        path: 'https://www.pcbaaa.com/capabilities/test-services',
        name: 'TEST SERVICES',
        meta: {
          title: 'home.testServices',
          isLink: true,
        },
      },
    ],
  },
  {
    name: 'Contacts',
    path: 'https://www.pcbaaa.com/contacts/',
    meta: {
      title: 'home.contacts',
      isLink: true,
    },
  },
];
