export default {
  coommon: {
    login: 'Login',

    // ------  表格
    index: 'NO',
    operation: 'Operation',
    sort: 'Order',
    create_time: 'Create time',
    start_date: 'Start date',
    end_date: 'End date',
    status: 'Status',
    // ------  通用
    tips: 'Tips',
    warning: 'Warning',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    success: 'Success',
    Nicknames: 'Nicknames',
    MembershipLevel: 'Membership Level',
  },

  // ------  菜单
  menu: {
    home: 'Home',
    smartQuote: 'Smart Quote',
    pcb: 'PCB',
    smt: 'SMT',
    bom: 'BOM',
    memberCentre: 'Member Center',
    myOrder: 'My Orders',
    privateInventory: 'Private Stock',
    accountInfor: 'Account Info',
    loginOut: 'Log out',
    login: 'Log in',
    subscribe: 'Subscribe',
    en: 'English',
    zh: 'Chinese',
    Vietnamese: 'Vietnamese',
    footerText:
      'IBE enables the world’s best brands. Through our unrivalled customer-centric approach, we partner with leading companies in numerous markets to deliver solutions for their most complex challenges.',
    findUs: 'Find Us',
    filing: 'Copyright © IBE Electronics 2024.',
    filingRight:
      ' Al Rights Reserved. IBE is a professional PCB manufacturer and PCB assembly supplier in China',
  },
  home: {
    boardType: 'Board Type',
    boardLayer: 'Board Layer',
    more: 'More',
    boardSize: 'Board Size',
    boardSum: 'Quantities',
    priceImmediately: 'Immediate Pricing',
    pcbServe: 'PCB Services',
    multilayerBoard: 'High Multilayer PCB',
    FPCSoftBoard: 'FPC flexible board',
    smtPatch: 'SMT SMD',
    fr: 'FR-4',
    SMDLayer: 'Chip Layer',
    SMTNumberLayers: 'Board Layer',
    SMDDevices: 'Chip Device ',
    solderJoints: 'Weld',
    patchType: 'kinds',
    fullSticker: 'Top and bottom full sticker',
    groundFloor: 'bottom-layer only',
    topLevel: 'Top posting only',
    aluminumPlate: 'Aluminum Plate',
    tscs: 'Thermoelectric Separation Copper Substrate',
    rogers: 'Rogers Rogers HF Board',
    pcbServeText:
      "1-32 layers pcb proofing, mass production service. Proofing 24 hours shipment without expediting fee，use of real A-grade boards, adhering to the factory's own production model，Guaranteeing quality reliability and stability.",
    orderNow: 'Order Now',
    multilayerBoardSeve: 'High Multilayer PCB Services',
    Ply64: '6 - 32 Layers',
    highBoard: 'High Precision Impedance Plates',
    multilayerBoardText:
      'New high multi-layer special production line, line anti-soldering using LDI, VCP pulse plating and other advanced production equipment, using brand-name boards, accurate impedance detection instruments, to provide high-definition characters. Advanced production equipment, use of brand-name plates, accurate impedance detection instruments, to provide high-definition characters, High-quality inspection standards, high-end packaging and transportation services.',
    FPCSoftBoardServe: 'FPC Flexible Board Service',
    supportPanel: 'Supports single and dual panel',
    performance: 'Good bending performance',
    FPCSoftBoardText:
      'Excellent price and high quality, support PI, FR4,  steel sheet, double-sided adhesive reinforcement and electromagnetic shielding film, using high-end adhesive-free electrolytic copper foil, high-temperature resistance and longer service life.',
    smtPatchServe: 'SMT Mounting Service',
    placementService: 'PCBA Mounting Services',
    waveSoldering: 'Insert wave soldering',
    doubleSidedSticker: 'Double-sided',
    smtPatchServeText:
      'Starting from $50, a penny a soldering point, support millions of components, fully automatic production line, so that your PCB can be completed in half a day at the earliest soldering.',
    whyChooseUs: 'WHY CHOOSE US',
    whyWork: 'Why More Than 3000 Clients Over the World',
    loveUs: 'Love to Work with Us',
    localService: 'Local Customer Service',
    localServiceText:
      'We have three factories in USA, Vietnam, China respectively to provide prompt pre-sales and after-sales service and technical support.',
    qualityAssurance: 'Quality Assurance',
    qualityAssuranceText:
      'ISO/TS/RoHS/REACH/UL/CE/FDA certificated, our commitment is to never compromise on the safety, compliance and quality of our products and services.',
    promptDelivery: 'Prompt Delivery',
    promptDeliveryText:
      'Two shifts in production department provide you faster delivery time. Quick-turn PCB prototypes can be delivered in 24 hours, PCBA prototypes within 3 days.',
    nonDisclosureAgreement: 'Non-disclosure agreement',
    nonDisclosureAgreementText:
      "We strictly follow the confidential agreements(NDA) to protect customers' interests to keep long term partnership with many renowned customers",
    countriesWorldwide: 'Countries Worldwide',
    countriesWorldwideText: 'USA/South America/EU/Middle East/Asia Area',
    happyCustomers: 'Happy Customers',
    happyCustomersText: 'Leading brands in medical/automotive/new energy field',
    goIbe: 'Enter IBE',
    viewVideo: 'View Video',
    learnMore: 'Learn more',
    productDescription: 'Produkte',
    cameraPCB: 'Camera PCB manufacturing and assembly for monitoring indoor and outdoor security',
    marineShip:
      'Marine ship control system and engine management system circuit board manufacturing company',
    aircraftAccelerometer:
      'Aircraft Accelerometer and Pressure Sensor PCB Manufacturing and Assembly',
    automotiveIntegrated:
      'Automotive Integrated Navigation and Entertainment Stereo System Printed Circuit Board Assembly Company',
    productQuality: 'Product Quality Assurance',
    productQualityText: 'UL, ISO certification, quality assurance',
    fastDelivery: 'Fast Delivery Guarantee',
    fastDeliveryText: 'Expedited batch, delivery time only 3-4 days',
    costPerformance: 'Cost Effective Guarantee',
    costPerformanceText: 'Nationwide shipping, free engineering fee',
    rawMaterials: 'Raw Material Guarantee',
    rawMaterialsText: 'Adopting high-quality A-grade boards',
    contacts: 'Contacts',
    testServices: 'TEST SERVICES',
    supplyChainServices: 'SUPPLY CHAIN SERVICES',
    afterSalesServices: 'AFTER SALES SERVICES',
    logisticsAndFulfillment: 'LOGISTICS AND FULFILLMENT',
    ODMSevice: 'OEM/ODM SERVICE',
    designAndEngineerings: 'DESIGN AND ENGINEERING',
    capabilities: 'Capabilities',
  },
  smt: {
    smtQuote: 'SMT Quote',
    smtOrder: 'SMT Order',
    SMTOnlineOrdering: 'SMT Online Ordering',
    PCBInfo: 'PCB Info',
    SMTInfo: 'SMT Info',
    productType: 'Product Type',
    PCBOrder: 'PCB Order',
    selectTheOrder: 'Select the order number of the PCB',
    placedPCBOrders: 'Placed PCB orders',
    selectPCBOrder: 'Please select PCB order',
    IBECustomizating: 'IBE Customizating',
    ownSupply: 'Own supply',
    generalInfo: 'General Info',
    chipLayer: 'chip layer',
    patchQuantity: 'Patch quantity',
    chipDevice: 'chip device',
    plugin: 'plug-in',
    topAndBottom: 'Top and bottom full sticker',
    topPostingOnly: 'Top posting only',
    bottomLayerOnly: 'bottom-layer only',
    defaultNumber: 'Default number of PCBs, maximum number of PCBs not allowed to be exceeded.',
    Weld: 'Weld',
    kinds: 'kinds',
    PleasenumberOfPatches: 'Please enter the number of patches',
    PleasesolderJointsForSMDDevices: 'Please enter the number of solder joints for SMD devices',
    PleasechipDeviceTypes: 'Please enter the number of chip device types',
    PleasepluginSolderJoints: 'Please enter the number of plug-in solder joints',
    PleasepluginTypes: 'Please enter the number of plug-in types',
    PleaseselectPatchLayer: 'Please select patch layer',
    BOMInfo: 'BOM Info',
    BOMFile: 'BOM file',
    searchForYourOrderNumber: 'Search for your order number',
    orderNo: 'Order No.',
    Ordertime: 'Order time',
    PCBFilename: 'PCB filename',
    productCategory: 'Product Category',
    orderStatus: 'Order Status',
    Cancel: 'Cancel',
    Define: 'Define',
    SelectMatedBOM: 'Select Mated BOM',
    Compatibility: 'Compatibility',
    DocumentComparisonAnalysis: 'Document Comparison Analysis',
    BOMDocument: 'BOM Document',
    UploadTime: 'Upload Time',
    TimeofDispatch: 'Time of Dispatch',
    AllModels: 'All Models',
    Numberofmodelsidentified: 'Number of models identified',
    Numberofmodelstobedetermined: 'Number of models to be determined ',
    Numberofunmatchedmodels: 'Number of unmatched models',
    MaterialPrice: 'Material Price (IBE) - Single Set',
    TheBOMfile: 'The BOM file has a total of',
    set_success: 'Setting successful',
    models: 'models',
    pleaseidentifythe: 'please identify the',
    notmatchexactly: 'models that do not match exactly',
    Reupload: 'Reupload',
    ConfirmationofBOMorder: 'Confirmation of BOM order',
    awaitingprocessing: 'awaiting processing',
    Inprogress: 'In progress',
    processingfailure: 'processing failure',
    Processingsuccess: 'Processing success',
    UploadBOMfiles: 'Upload BOM files, smart quotes',
    Wewillcalculate:
      'We will calculate your BOM price through intelligent search and automatic matching.',
    SupportExcelfile: 'Support Excel file compatible formats (*.xlsx,*.xls)',
    SelectBOM: 'Select BOM',
    yourcomponentkeywords: 'Search for your component keywords',
    BOMInformation: 'BOM Information',
    Numberofpatches: 'Number of patches',
    PreparedComponents: 'Prepared Components',
    Privatestock: 'Private stock',
    IBEorderrequired: 'IBE order required',
    Tobesent: 'To be sent',
    Coordinatefile: 'Coordinate file',
    Uploadingfiles: 'Uploading files',
    Uploadcoordinatefile: 'Upload coordinate file',
    Basicservices: 'Basic services',
    Customizedservices: 'Customized services',
    PackagingRequirements: 'Packaging Requirements',
    Expressway: 'Express way',
    Selectingacouriermethod: 'Selecting a courier method',
    Shippingaddress: 'Shipping address',
    Selectshippingaddress: 'Select shipping address',
    DeliveryAddress: 'Delivery Address',
    Addshippingaddress: 'Add shipping address',
    Corporation: 'Corporation',
    Individuals: 'Individuals',
    Name: 'Name',
    LastName: 'Last Name',
    CountryArea: 'Country / Area',
    CityRegion: 'City/Region',
    StreetAddress: 'Street Address',
    HouseNumber: 'House Number',
    MailboxCode: 'Mailbox Code',
    MobileNumber: 'Mobile Number',
    CompanyIdentification: 'Company Identification',
    EORItaxcode: 'EORI tax code',
    Save: 'Save',
    Address: 'Address',
    Operational: 'Operational',
    Edit: 'Edit',
    Deleting: 'Deleting',
    DefaultAddresses: 'Default Addresses',
    Settingthedefaultaddress: 'Setting the default address',
    deletethecurrentaddress: 'Whether to delete the current address',
    SmartQuote: 'Smart Quote',
    PCBBilling: 'PCB Billing',
    Laminate: 'Laminate',
    Dend: 'Dend',
    SMTPricing: 'SMT Pricing',
    BOMPricing: 'BOM Pricing',
    Devices: 'Devices',
    Taxonomy: 'Taxonomy',
    Taxes: 'Taxes',
    Valueaddedtax: 'Value-added tax (VAT)',
    Aggregate: 'Aggregate',
    SubmitOrder: 'Submit Order',
    Disclaimer: 'I am aware of the Disclaimer.',
    Fulladdress: 'Full address',
  },
  pcb: {
    PCBfileformatmust:
      'PCB file format must be in the form of a compressed package (e.g. rar, zip); does not contain  /; *? "<> and other special characters size of not more than 50M.',
    UploadGerberfiles: 'Upload Gerber files, Smart quotes',
    BasicInfo: 'Basic Info',
    MaterialType: `
    <h3>Material Type</h3>
    ①If the order requires an aluminum core board but the material selected is FR-4, complaints will not be accepted for any errors made; the same applies in reverse.
    <br/>
    ② If the order selects FR-4 material, but there is a note in the attachment, or a note in the materials, or any other form of indication that it is an aluminum core board, but the order was not selected as an aluminum core board, the order will prevail, and complaints will not be accepted; the same applies in reverse.
    <br/>
    ③Thermoelectric separation copper-based boards must meet the following requirements: Minimum hole size >1.0MM, Minimum slot size >1.6MM.
    `,
    BoardSizeDesc: `
    <h3>Board Size</h3>
    Refers to the length and width dimensions of the customer's delivery board.
    <br/>
    ① If it is a single-piece circular board delivery, the length and width dimensions refer to the diameter of the circle.
    <br/>
    ② If it is an irregular shape delivery, the length and width dimensions are calculated based on the maximum values in the horizontal and vertical directions. To reduce costs, irregular boards are recommended to be panelized and shipped by a third party.
    `,

    BoardQuantityDesc: `
    <h3>Board Quantity</h3>
    Refers to the quantity of the delivery board status.
  <br/>
  ① If it is a single-piece delivery, it is calculated based on the number of single pieces.
  <br/>
  ② If it is a connected piece delivery, it is calculated based on the number of connected pieces.
  <br/>
  ③ The order quantity should be an integer as available in the system's order selection, and the delivery quantity shall be based on the marking of the factory packaging and shipment.
    `,
    PlateType: 'Plate Type',
    BoardSize: 'Board Size',
    ofboards: 'No. of boards',
    ProductType: 'Product Type',
    IndustrialConsumerOtherElectronics: 'Industrial/Consumer/Other Electronics',
    Auto: 'Auto',
    Healthcare: 'Healthcare',
    PCBProcessInformation: 'PCB Process Information',
    SMTOptions: 'SMT Options',
    SMTornot: 'SMT or not',
    Noneed: 'No need',
    Takeup: 'Take up',
    Taxes: 'Taxes',
    Notavailable: 'Not available',
    Courierfee: 'Courier fee',
    FedEx: 'FedEx',
    Valueadded: 'Value-added Tax (VAT)',
    ProcessFee: 'Process Fee',
    OrderProcessCosts: 'Order Process Costs',
    ServiceFee: 'Service Fee',
    Amountofservicefee: 'Amount of service fee',
    DeliveryEstimation: 'Delivery Estimation',
    pcbonlineordering: 'pcb online ordering',
    pcbfile: 'pcb file',
    UploadPCBfile: 'Upload PCB file',
    Uploadfilesin: 'Upload files in (.rar .zip) format with a file size of 50M or less.',
    pictureis: '3D picture is for reference only, the specific in kind shall prevail',
    Physicalsimulation: 'Physical simulation',
    Fullscreenview: 'Fullscreen view',
    PCBpricing: 'PCB pricing',
  },
  bom: {
    BOMQuote: 'BOM Quote',
    UploadBOMfiles: 'Upload BOM files, smart quotes',
    Wewillcalculateyour:
      'We will calculate your BOM price through intelligent search and automatic matching.',
    SupportExcel: 'Support Excel file compatible formats (*.xlsx,*.xls)',
    DownloadBOMfiletemplate: 'Download BOM file template',
    Privatestock: 'Private stock',
    Prepareinadvance: 'Prepare in advance and get more benefits',
    Ownmaterial: 'Own material',
    Privatematerial: 'Private material, mailed for use',
    IBEFeeding: 'IBE Feeding',
    Completerange: 'Complete range, transparent pricing, direct access',
    BOMQuotationProcess: 'BOM Quotation Process',
    Step1: 'Step 1',
    Step2: 'Step 2',
    Step3: 'Step 3',
    Step4: 'Step 4',
    UploadBOMfile: 'Upload BOM file',
    BOMIntelligent: 'BOM Intelligent Matching/Selection',
    STMordering: 'STM ordering/mailing list',
    Payment: 'Payment/Postal',
    Pleaseselectthenumbe: 'Please select the number of patches',
    Otherquantities: 'Other quantities',
    Pleaseenterthenumber: 'Please enter the number of patches',
  },
  use: {
    OrderManagement: 'Order Management',
    SMTOrder: 'SMT Order',
    PCBOrder: 'PCB Order',
    Privatestock: 'Private stock',
    Privateinventorymanagement: 'Private inventory management',
    MailingComponents: 'Mailing Components',
    AccountInformation: 'Account Information',
    MyProfile: 'My Profile',
    ShippingAddress: 'Shipping Address',
    Securitysettings: 'Security settings',
  },
  om: {
    OrderDate: 'Order Date',
    Startdate: 'Start date',
    Enddate: 'End date',
    AllOrderTypes: 'All Order Types',
    AllOrderStatus: 'All Order Status',
    Search: 'Search',
    OrderNo: 'Order No.',
    Timeoforder: 'Time of order',
    Postal: 'Postal',
    Estimateddelivery: 'Delivery time (days)',
    Prices: 'Prices',
    OrderStatus: 'Order Status',
    OrderOperation: 'Order Operation',
    PCBInfo: 'PCB Info',
    Sheets: 'Sheets',
    BoardSize: 'Board Size',
    Platelayer: 'Plate layer',
    ofboards: 'No. of boards',
    GerberDocument: 'Gerber Document',
    gotothemail: 'go to the mail',
    Tobemailed: 'To be mailed',
    Progressfollowup: 'Progress follow-up',
    Order: 'Order',
    Pay: 'Pay',
    Audits: 'Audits',
    Productive: 'Productive',
    Shipments: 'Shipments',
    Cancel: 'Cancel',
    ViewDetails: 'View Details',
    CancelOrder: 'Cancel Order',
    FactorySupply: 'Factory Supply',
    Mailed: 'Mailed',
    Inthemail: 'In the mail',
    Receivedgoods: 'Received goods',
    TopBottom: 'Top + Bottom',
    Attic: 'Attic',
    Bottom: 'Bottom',
    Component: 'Component',
    chippcb: 'chip pcb',
    PCBOrder: 'PCB Order',
    OrderInfo: 'Order Info',
    PlaceSMTorders: 'Place SMT orders',
    OrderOperation: 'Order Operation',
    Layer: 'Layer',
    Chip: 'Chip',
    SerialNo: 'Serial No.',
    bompreparation: 'Record of bom preparation',
    MailingComponents: 'Mailing Components',
    Stockpile: 'Stockpile',
    Lastupdatedtime: 'Last updated time',
    Valueofgoods: 'Value of goods',
    Operate: 'Operate',
    ViewUpdateLog: 'View Update Log',
    branding: 'branding',
    OriginalModel: 'Original Model',
    ComponentParameters: 'Component Parameters',
    Batches: 'Batches',
    Datasheet: 'Datasheet',
    Download: 'Download',
    Wewillcalculate:
      'We will calculate your BOM price through intelligent search and automatic matching.',
    SupportExcelfile: 'Support Excel file compatible formats (*.xlsx,*.xls)',
    DownloadBOMfile: 'Download BOM file template',
    Pleaseentercomponentkeywords: 'Please enter component keywords.',
    ExportBOM: 'Export BOM',
    Deleting: 'Deleting',
    IBEorderrequired: 'IBE order required',
    Modificationofmaterial: 'Modification of material preparation',
    UnlockingPrivateInventory: 'Unlocking Private Inventory',
    BOMMatchingOrderOnlineQuotation: 'BOM Matching Order Online Quotation',
    BOMfile: 'BOM file',
    BOMCode: 'BOM Code',
    AllModels: 'All Models',
    Determinedmodel: 'Determined model',
    Modeltobedetermined: 'Model to be determined',
    Nomatchingmodel: 'No matching model',
    Estimatedpriceperset: 'Estimated price per set',
    Estimatedtotalprice: 'Estimated total price',
    Quotationvaliditytime: 'Quotation validity time',
    All: 'All',
    IBEstocking: 'IBE stocking',
    Unmatched: 'Unmatched',
    Postagerequired: 'Postage required',
    Notsticker: 'Not sticker',
    UsingPrivateStock: 'Using Private Stock Components',
    BOMModel: 'BOM Model',
    BOMBit: 'BOM Bit No.',
    Branding: 'Branding',
    Componentparameters: 'Component parameters',
    RecommendedComponents: 'Recommended Components',
    Dosage: 'Dosage',
    Currentstock: 'Current stock',
    IBEPreparation: 'IBE Preparation Components',
    Subtotalpurchases: 'Subtotal purchases',
    Replacementmodel: 'Replacement model',
    Convertedtomailing: 'Converted to mailing',
    Compatibility: 'Compatibility',
    SupplierName: 'Supplier Name',
    Supplierinternalmodel: "Supplier's internal model",
    StandardPackingQuantity: 'Standard Packing Quantity SPQ',
    Vendorinventory: 'Vendor inventory',
    Perfectmatch: 'Perfect match',
    Mismatch: 'Mismatch',
    Modeltobeconfirmed: 'Model to be confirmed',
    Modelandbrand: 'Model and brand to be confirmed',
    Confirmed: 'Confirmed',
    UnmatchedComponents: 'Unmatched Components',
    Componentstobemailed: 'Components to be mailed',
    MailingQuantity: 'Mailing Quantity',
    PriceReference: 'Price Reference',
    UnpostedComponents: 'Unposted Components',
    GoplaceanSMTorder: 'Go place an SMT order.',
    Inthemail: 'In the mail',
    Done: 'Done',
    Cancelled: 'Cancelled',
    Pleaseenterthecouriernumber: 'Please enter the courier number',
    Mailingtime: 'Mailing time',
    Deliverynote: 'Delivery note',
    OrderNo: 'Order No.',
    MailedPCBs: 'Mailed PCBs',
    Statuses: 'Statuses',
    ComponentInfo: 'Component Info',
    Arrivaltime: 'Arrival time',
    Cancellationdate: 'Cancellation date',
    Numberofdemands: 'Number of demands',
    AddPackage: 'Add Package',
    addedtothepackage: '',
    Addparcelsuccessfully: 'Add parcel successfully',
    Minimummailingrequired: 'Minimum mailing required',
    packagewillberemoved: 'Confirmation that the package will be removed?',
    Removingpackages: 'Removing packages',
    Prompts: 'Prompts',
    onthecomponentpackage: 'Whether or not the component code is on the component package',
    Yes: 'Yes',
    No: 'No',
    Unanimous: 'Unanimous',
    ComponentMailingList: 'Confirmation of Component Mailing List',
    addree: '1/F, Building E, Industrial Area No. 1, Tongtou Community',
    tel: 'Recipient Tel: 1882256040',
    AddresseeAddress:
      'Addressee Address: Guangdong Province-Zhuhai City-Doumen District Qianwu Town, Lishan Village, Advanced Electronics, Building 4, SMT warehouse 6194522.',
    Pleasefillintheaddress:
      'Please fill in the address strictly according to the above, IBE will not be responsible for the loss of the courier due to filling in the wrong information.',
    ExpressName: 'Express Name',
    nameofthecourier: 'Please enter the name of the courier',
    TrackingNumber: 'Tracking Number ',
    deliverynotenumber: 'Please enter the delivery note number',
    MailContacts: 'Mail Contacts',
    Pleaseenteranemailcontact: 'Please enter an e-mail contact',
    MailContactPhone: 'Mail Contact Phone',
    contactphonenumber: 'Please enter the email contact phone number',
    AddCourierNumber: 'Add Courier Number',
    ConfirmationofComponent: 'Confirmation of Component Mailing List',
    MailingMaterials: 'Notes on Mailing Materials',
    Mailingmaterialdoes:
      ' Mailing material does not mean that it will be mounted, please be sure to place an SMT order when you choose to mail material inventory.',
    Inordertospeedup:
      ' In order to speed up PCB production, the mailed devices will not be inspected, and the IBE will be put into the warehouse directly according to the corresponding C number. ',
    outofthewarehouse:
      'In the [components and devices ready] out of the warehouse, such as the number of mailings is not enough to order the number needed to accept [how many posting how much]. ',
    Ifthereisanydiscrepancybetween:
      'If there is any discrepancy between the mailed model [package] and the [package in the mailing list], pin oxidation, or damage to the device, accept the IBE to skip the non-welding or pin soldering.',
    MailingDevices: 'Mailing devices out of storage and loss according to IBE standards. ',
    PCBgenerationstarts: ' PCB generation starts after the signing of the mailed devices.',
    Thedeliverytime:
      'The delivery time of the order will be calculated after the signing and acceptance of the mailed devices and PCBs.',
    Nopickup: ' No pickup of mailed devices.',
    acceptbatteriesbymail: 'IBE does not accept batteries by mail.',
    Ihavereadandagree: 'I have read and agree to the above Material Mailing Notes.',
    CancelMailing: 'Cancel Mailing',
    Confirmationofmailing: 'Confirmation of mailing',
    Interchangeability: 'Interchangeability',
    Usernickname: 'User nickname',
    Country: 'Country',
    Cellphonenumber: 'Cell phone number',
    Inbox: 'Inbox',
    Sector: 'Sector',
    ConsumerElectronics: 'Consumer Electronics',
    Telecommunicate: 'Telecommunicate',
    Aerospace: 'Aerospace',
    MedicalCare: 'Medical Care',
    CompanyName: 'Company Name',
    Save: 'Save',
    BillingAddresses: 'Billing Addresses',
    Addshippingaddress: 'Add shipping address',
    AddBillingAddress: 'Add Billing Address',
    Names: 'Names',
    Phonenumber: 'Phone number',
    Fulladdress: 'Full address',
    currentaddressdeleted: 'Is the current address deleted?',
    Loginpassword: 'Login password',
    ResetPassword: 'Reset Password',
    emailaddress: 'E-mail address',
    ChangeMailbox: 'Change Mailbox',
    Pleaseenteroldpassword: 'Please enter old password',
    Pleaseenternewpassword: 'Please enter new password',
    newpasswordtoconfirm: 'Please enter a new password to confirm',
    Submit: 'Submit',
    Originalpasswordisincorrect: 'Original password is incorrect',
    Passwordchangedsuccessfully: 'Password changed successfully',
    OldMailboxVerification: 'Old Mailbox Verification',
    GetCAPTCHA: 'Get CAPTCHA',
    Sentsuccessfully: 'Sent successfully, please check your mailbox',
    Settingupanew: 'Setting up a new e-mail address',
    Pleaseloginfirst: 'Please log in first',
    Login: 'Log in',
    Subscribe: 'Subscribe',
    Forgotyourpassword: 'Forgot your password?',
    Notamember: 'Not a member yet?',
    SignUpNow: 'Sign Up Now',
    Retrievepassword: 'Retrieve password',
    Pleaseenteryouremail: 'Please enter your email',
    Pleaseenterthecode: 'Please enter the code',
    Pleaseenteranewpassword: 'Please enter a new password',
    MemberRegistration: 'Member Registration',
    Alreadyhaveamember: 'Already have a member account?',
    UserServiceAgreement: 'Read and agree to the User Service Agreement',
    EmailAccount: 'Email Account',
    PIN: 'PIN',
    ConfirmPassword: 'Confirm Password',
    RegisterEmailVerification: 'Register Email Verification',
    RegisterSuccessfully: 'Register Successfully',
    GotoPost: 'Go to Post',
  },

  mall: {
    logout_prompt: 'Do you want to log out?',
    member_login: 'Member Login',
    accept: 'Accept',
    do_not_accept: 'Do Not Accept',
    accept_the_batch: 'Accept Batch',
    unlimited: 'Unlimited',
    one_year: '1 Year',
    two_years: '2 Years',
    three_years: '3 Years',
    upload_file_support:
      "Only <span style='color: #ff0000;'>XLS、XLSX</span> files are supported. Please organize the files according to the upload rules, otherwise it will cause data parsing exceptions.",
    upload_bom_file: 'Upload BOM file',
    file_standard_template: 'File Standard Template',
    smt_process: 'SMT Process',
    based_on_actual_costs: 'Based on actual costs',
    layer_board: '{num} layer board',
    pcb_pricing: 'PCB Pricing',
    estimated_cost: 'Estimated Cost',
    final_cost_subject_to_review: 'Final cost is subject to actual review',
    disclaimer: 'Disclaimer',
    read_disclaimer: 'I have read the ',
    please_select: 'Please select ',
    sets: '{num} sets',
    process_service: 'Process Service',
    delivery_and_shipping: 'Delivery & Shipping',
    delivery_time: 'Delivery Time',
    days: '{num} days',
    express_delivery: 'Express Delivery',
    shipping_time: 'Shipping Time',
    price_and_costs: 'Price & Costs',
    pcb_cost: 'PCB Cost',
    smt_cost: 'SMT Cost',
    smt_processing_fee: 'SMT Processing Fee',
    smt_process_service_fee: 'SMT Process Service Fee',
    bom_cost: 'BOM Material Cost',
    logistics_fee: 'Logistics Fee',
    tax_fee: 'Tax Fee',
    quick_match_type_selection: 'Quick Match Type Selection',
    quick_match_pros: 'Pros: No need to wait, quickly get matching selection',
    quick_match_cons: 'Cons: Matching degree has certain errors',
    deep_match_type_selection: 'Deep Match Type Selection',
    deep_match_pros: 'Pros: Higher matching degree, higher accuracy, lower errors',
    deep_match_cons: 'Cons: Longer time, need to wait',
    length: 'Length',
    width: 'Width',
    next_step: 'Next Step',
    submit_pcb_order: 'Submit PCB Order',
    pcb_information: 'PCB Information',
    process_information: 'Process Information',
    shipping_information: 'Shipping Information',
    change_address: 'Change Address',
    add_shipping_information: 'Add Shipping Information',
    edit_shipping_information: 'Edit Shipping Information',
    add: 'Add',
    recipient: 'Recipient',
    mobile_number: 'Mobile Number',
    region: 'Region',
    shipping_address: 'Shipping Address',
    postal_code: 'Postal Code',
    default_address: 'Default Address',
    cash_on_delivery: 'Cash on Delivery',
    based_on_actual_cost: 'Based on actual cost',
    based_on_actual_time: 'Based on actual time',
    payment_method: 'Payment Method',
    material_category: 'Material Category',
    recipient_name: 'Recipient Name',
    enter_recipient_name: 'Enter Recipient Name',
    enter_mobile_number: 'Enter Mobile Number',
    enter_your_region: 'Enter your region',
    enter_the_shipping_addres: 'Enter the shipping addres',
    detailed_address_info:
      'Enter detailed address information, such as street, house number, community, building number, unit, etc.',
    set_as_default_address: 'Set as Default Address?',
    enter_postal_code: 'Enter Postal Code',
    valid_mobile_number: 'Enter a valid mobile number',
    add_address_failed: 'Failed to add address, please try again later',
    area_code_cannot_be_empty: 'The area code cannot be empty',
    added_successfully: 'Added Successfully',
    edit_address_failed: 'Failed to edit address, please try again later',
    set_default_failed: 'Failed to set default, please try again later',
    delete_successful: 'Deleted Successfully',
    enter_smt_quantity: 'Enter SMT Quantity',
    select_an_order: 'Select an Order',
    download_bom: 'Download BOM',
    proceed_to_smt_order: 'Proceed to SMT Order',
    reassignment_successful: 'Reassignment Successful',
    pending_confirmation_of_bom:
      'Pending confirmation of BOM, please confirm BOM model before submitting',
    mismatched_bom: 'Mismatched BOM, please replace unmatched models and confirm before submitting',
    smt_quantity_exceed_pcb: 'SMT quantity cannot exceed PCB quantity',
    proceed_to_payment: 'Proceed to Payment',
    confirm_bom: 'BOM Confirmation',
    confirm_order: 'Confirm Order',
    previous_step: 'Previous Step',
    model: 'Model',
    brand: 'Brand',
    package: 'Package',
    component_parameters: 'Component Parameters',
    price: 'Price',
    inventory: 'Inventory',
    date_code: 'Date Code',
    product_link: 'Product Link',
    view_details: 'View Details',
    accept_alternative_components: 'Alternative components',
    select_materials: 'Select Materials',
    enter_brand: 'Enter Brand',
    enter_component_model: 'Enter Component Model',
    reset: 'Reset',
    material_search: 'Material Search',
    modification_successful: 'Modification Successful',
    serial_number: 'Line',
    add_to_smt: 'Add to SMT',
    confirmation_successful: 'Confirmation Successful',
    confirm_change_to_non_smt: 'Confirm to change model ... to non-SMT?',
    confirm_add_to_smt: 'Confirm to add model {name} to SMT?',
    confirm_model: 'Confirm model {name}?',
    confirm_change_to_mail: 'Confirm to change model {name} to mail?',
    enter_account_number: 'Enter Account Number',
    enter_password: 'Enter Password',
    password_min_length: 'Password length cannot be less than {num} characters',
    captcha_verification: 'Complete CAPTCHA verification',
    verification_failed: 'Verification Failed',
    payment_successful: 'Payment Successful',
    payment_canceled: 'Payment Canceled',
    back: 'Back',
    quote: 'Quote',
    order: 'Order',
    select_courier_company: 'Select Courier Company',
    billing_address: 'Billing Address',
    shipping_information: 'Shipping Information',
    smt_quantity: 'SMT Quantity',
    upload_file_limit: 'File size cannot exceed{num}MB',
    contact_information: 'Contact Information',
    select_smt_quantity: 'Please select SMT quantity first',
    testing_services: 'Testing Services',
    supply_chain_services: 'Supply Chain Services',
    after_sales_service: 'After Sales Service',
    logistics_and_fulfillment: 'Logistics and Fulfillment',
    oem_odm_services: 'OEM/ODM Services',
    engineering_design: 'Engineering Design',
    performance: 'Performance',
    page_not_found: 'Sorry, the page you visited does not exist',
    back_to_previous_page: 'Back to Previous Page',
    back_to_homepage: 'Back to Homepage',
    quantity_cannot_be_empty: 'Quantity cannot be empty',
    enter_quantity: 'Enter Quantity',
    browser_not_support_video: 'Your browser does not support video playback.',
    check_email: 'Sent successfully, please check your email',
    password_retrieval_successful: 'Password retrieval successful',
    enter_login_email_or_username: 'Enter login email or username',
    upload_successful: 'Upload Successful',
    delete_successful: 'Delete Successful',
    confirm_removal_from_cart: 'Confirm to remove {name} from the package?',
    component_package_instruction:
      'On the component packaging bag, you can use any method to stick or place the component number/component model, as long as the number or component model is clearly visible on the packaging. If not placed, it will be very troublesome for staff to check the components, which may affect the order progress.',
    select_all_first: 'Please select all first',
    delete_order_confirmation: 'Confirm to delete order information of ...',
    confirm_add_all_components_to_cart: 'Confirm to add all components of {name} to the package?',
    invoice: 'Invoice',
    component_information: 'Component Information',
    suggested_quantity: 'Suggested Quantity',
    shipping_info_update_success: 'Successfully updated shipping information',
    operation_failed: 'Operation Failed',
    refund_failed_due_to_association:
      'Sorry, cannot refund! This order is associated with PCB order number {name} . To refund, please handle the refund of the associated PCB order first.',
    application_successful: 'Application Successful, we will process it as soon as possible',
    place_order: 'Place Order',
    order_settlement: 'Order Settlement',
    permanent_delete_confirmation: 'Deleted files cannot be recovered, are you sure to delete?',
    export_file_confirmation: 'Do you want to export this file?',
    creation_date: 'Creation Date',
    status: 'Status',
    save_successful: 'Save Successful',
    upload_successful: 'Upload Successful',
    select_placed_pcb_order: 'Please select the placed PCB order first',
    smt_quantity_exceed_pcb_order: 'SMT quantity cannot exceed the quantity of the PCB order',
    bom_file_cannot_be_empty: 'BOM file cannot be empty',
    confirm_bom_file: 'Please confirm the BOM file first',
    coordinate_file_cannot_be_empty: 'Coordinate file cannot be empty',
    confirm_coordinate_file: 'Please confirm the coordinate file first',
    agree_terms_checkbox: 'Please check to agree to the terms',
    switching_product_type_warning:
      'Switching product type will not retain the original data, do you want to continue?',
    input_value_exceed: 'Input value cannot exceed {num}',
    matching: 'Matching',
    pending_payment: 'Pending Payment',
    production_confirmation_pending: 'Production Confirmation Pending',
    in_production: 'In Production',
    awaiting_shipment: 'Awaiting Shipment',
    awaiting_receipt: 'Awaiting Receipt',
    received: 'Received',
    completed: 'Completed',
    refunding: 'Refunding',
    refunded: 'Refunded',
    cancelled: 'Cancelled',
    deleted: 'Deleted',
    no_parsing_data: 'No parsing data for this file',
    select_again: 'Select Again',
    order_quantity: 'Order Quantity',
    successfully_changed_to_mail: 'Successfully changed to mail',
    specification_sheet: 'Specification Sheet',
    view_order: 'View Order',
    matching_options_method: 'Matching options method',
    brand_to_be_confirmed: 'Brand to be confirmed',
    choose_delivery_address: 'Please choose a delivery address',
    choose_delivery_method: 'Please choose a delivery method',
    yes: 'Yes',
    no: 'No',
    component_total: 'total components',
    item_count: 'items',
    quantity_exceeds_total: 'Shipping quantity cannot exceed total quantity',
    confirm_modify_delete_order: 'Modify order information for {name} and confirm deletion?',
    default: 'Default',
    order_successful: 'Order successful',
    complete_payment_soon: 'Please complete the payment soon',
    deep_matching_in_progress:
      'Deep matching in progress, estimated time: 15-30 minutes, please wait patiently!',
    choose_material_type: 'Please choose material type',
    choose_board_layer: 'Please choose board layer',
    enter_board_quantity: 'Please enter board quantity',
    choose_substitute_material: 'Please choose whether to accept substitute material',
    choose_batch: 'Please choose batch',
    bom_number: 'BOM Number',
    name: 'Name',
    all_models: 'All Models',
    process_type: 'Process Type',
    view: 'View',
    confirm: 'Confirm',
    package_solder_point_confirmation: 'Package Solder Point Confirmation',
    component_quantity: 'Component Quantity',
    solder_point_count: 'Solder Point Count',
    refund_review_status: 'Refund Review Status',
    enter_refund_number: 'Please enter refund number',
    refund_number: 'Refund Number',
    application_time: 'Application Time',
    order_type: 'Order Type',
    refund_amount: 'Refund Amount',
    refund_time: 'Refund Time',
    refund_reason: 'Refund Reason',
    under_review: 'Under Review',
    processing_refund: 'Processing Refund',
    refunded: 'Refunded',
    cancelled: 'Cancelled',
    rejected: 'Rejected',
    confirm_cancel: 'Are you sure you want to cancel?',
    confirm_delete: 'Are you sure you want to delete {name}?',
    password_change: 'Password Change',
    password_change_success: 'Password change successful! Please log in again',
    email_change: 'Email Change',
    email_change_success: 'Email change successful! Please log in again',
    group_package: 'Group Package',
    cancel_success: 'Cancel successful',
    confirm_cancel_order: 'Do you want to cancel the order?',
    complete: 'Complete',
    refund: 'Refund',
    match: 'Match',
    estimated_time: 'Estimated time: 15-30 minutes',
    reupload_gerber_file: 'Reupload Gerber file',
    upload_gerber_first: 'Please upload Gerber file first',
    payment: 'Payment',
    production: 'Production',
    shipment: 'Shipment',
    receipt: 'Receipt',
    position_number: 'Position Number',
    uploading: 'Uploading',
    bom_preparation: 'BOM Preparation',
    refund_records: 'Refund Records',
    refund: 'Refund',
    enter_order_number: 'Please enter order number',
    modify: 'Modify',
    contact_phone: 'Contact Phone',
    payment_time: 'Payment Time',
    order_time: 'Order Time',
    order_details: 'Order Details',
    all: 'All',
    based_on_review_time: 'Based on the review time',
    to_be_confirmed: 'To be confirmed',
    confirmed: 'Confirmed',
    export: 'Export',
  },
};
