// 获取所有的图片
const imageModules = import.meta.glob('/src/assets/images/**/*');

const images = {};
for (const path in imageModules) {
  const imageName = path.split('/').pop().split('.')[0]; // 获取图片名字并去除文件后缀
  imageModules[path]().then((module) => {
    // 将图片的 URL 添加到 images 对象中，以图片名字作为键名
    images[imageName] = module.default;
  });
}

export default images;
