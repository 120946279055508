export default {
  coommon: {
    login: '登录',
    // ------  表格
    index: '序号',
    operation: '操作',
    // ------  通用
    tips: '提示',
    warning: '警告',
    confirm: '确 定',
    cancel: '取 消',
    close: '关 闭',
    save: '保 存',
    success: '成功',
    Nicknames: '昵称',
    MembershipLevel: '会员等级',
  },
  // ------  菜单
  menu: {
    home: '首页',
    smartQuote: '智能报价',
    pcb: 'PCB',
    smt: 'SMT',
    bom: 'BOM',
    memberCentre: '会员中心',
    myOrder: '我的订单',
    privateInventory: '私有库存',
    accountInfor: '账户资料',
    loginOut: '退出账号',
    login: '登录',
    subscribe: '注册',
    en: '英文',
    zh: '中文',
    Vietnamese: '越南语',
    footerText:
      'IBE为全球最优秀的品牌提供支持。通过我们无与伦比的以客户为中心的方法, 我们与众多市场中的领先企业合作，为他们最复杂的挑战提供解决方案。',
    findUs: '查找我们：',
    filing: '2024年，版权归IBE电子产品所有，',
    filingRight: '保留所有权利。IBE是中国专业的 PCB 制造商和 和 PCB 组装供应商。',
  },
  home: {
    boardType: '板子类型',
    boardLayer: '板子层数',
    more: '更多',
    boardSize: '板子尺寸',
    boardSum: '数量',
    priceImmediately: '立即计价',
    pcbServe: 'PCB服务',
    multilayerBoard: '高多层PCB',
    FPCSoftBoard: 'FPC软板',
    smtPatch: 'SMT贴片',
    fr: 'FR-4',
    SMDLayer: '贴片层',
    SMTNumberLayers: '贴片层数',
    SMDDevices: '贴片器件',
    solderJoints: '焊点',
    patchType: '种类',
    fullSticker: '顶层、底层全贴',
    groundFloor: '只贴底层',
    topLevel: '只贴顶层',
    aluminumPlate: '铝基板',
    tscs: '热电分离铜基板',
    rogers: 'Rogers罗杰斯高频板',
    pcbServeText:
      '1-32层pcb打样，批量生产服务。打样24小时出货免加急费，使用真A级板材，坚持工厂自营的生产模式，保障品质可靠性和稳定性。',
    orderNow: '立即下单',
    multilayerBoardSeve: '高多层PCB服务',
    Ply64: '6 - 32层板',
    highBoard: '高精密阻抗板',
    multilayerBoardText:
      '全新高多层专用生产线，线路防焊采用LDI，VCP脉冲电镀等行业先进生产设备，使用名牌板材，精准的阻抗检测仪器，提供高清字符、高品质检验标准，高端包装及运输服务。',
    FPCSoftBoardServe: 'FPC软板服务',
    supportPanel: '支持单双面板',
    performance: '弯折性能好',
    FPCSoftBoardText:
      '价格优、品质高，支持PI、FR4、钢片、双面胶补强及电磁屏蔽膜，使用高端无胶电解铜箔，耐高温，使用寿命更长。',
    smtPatchServe: 'SMT贴片服务',
    placementService: 'PCBA贴装服务',
    waveSoldering: '插件波峰焊',
    doubleSidedSticker: '双面贴',
    smtPatchServeText:
      '50元起贴，一分钱一个焊点，支持上百万种元器件，全自动生产线，让你的PCB最快可以在半天内完成焊接。',
    whyChooseUs: '为何选择我们',
    whyWork: '为何有全球 3000 多名客户的喜爱',
    loveUs: '与我们合作',
    localService: '当地客服',
    localServiceText:
      '我们在美国、越南和中国分别设有三家工厂，为客户提供及时的售前、售后服务和技术支持.',
    qualityAssurance: '质量保障',
    qualityAssuranceText:
      '通过 ISO/TS/RoHS/REACH/UL/CE/FDA 认证,我们永久承诺和保证我们产品和服务的质量、安全性和合规性。',
    promptDelivery: '及时交付',
    promptDeliveryText:
      '为了向你提供更快的交货时间，生产部门两班倒。我们能保证24小时内交付快速电路板样板、3 天内交付 PCBA 原型。',
    nonDisclosureAgreement: '保密协议',
    nonDisclosureAgreementText:
      '我们严格遵守保密协议（NDA），以保护客户的利益 ，与许多知名客户保持长期合作关系。',
    countriesWorldwide: '全球各国',
    countriesWorldwideText: '美国/南美/欧盟/中东/亚洲地区',
    happyCustomers: '满意的客户',
    happyCustomersText: '医疗/汽车/新能源领域的领先品牌',
    goIbe: '走进IBE',
    viewVideo: '查看视频',
    learnMore: '了解更多',
    productDescription: '产品介绍',
    cameraPCB: 'Camera PCB manufacturing and assembly for monitoring indoor and outdoor security',
    marineShip:
      'Marine ship control system and engine management system circuit board manufacturing company',
    aircraftAccelerometer:
      'Aircraft Accelerometer and Pressure Sensor PCB Manufacturing and Assembly',
    automotiveIntegrated:
      'Automotive Integrated Navigation and Entertainment Stereo System Printed Circuit Board Assembly Company',
    productQuality: '产品质量保障',
    productQualityText: 'UL,ISO认证，品质保障',
    fastDelivery: '快速交货保障',
    fastDeliveryText: '批量加急，交期仅3-4天',
    costPerformance: '高性价比保障',
    costPerformanceText: '全国包邮，免收工程费',
    rawMaterials: '原材料保障',
    rawMaterialsText: '采用高质量A级板材',
    contacts: '联系方式',
    testServices: '测试服务',
    supplyChainServices: '供应链服务',
    afterSalesServices: '售后服务',
    logisticsAndFulfillment: '物流与履行',
    ODMSevice: 'OEM/ODM 服务',
    designAndEngineerings: '工程设计',
    capabilities: '性能',
  },
  smt: {
    smtQuote: 'SMT报价',
    smtOrder: 'SMT 下单',
    SMTOnlineOrdering: 'SMT 在线下单',
    PCBInfo: 'PCB信息',
    SMTInfo: 'SMT信息',
    productType: '产品类型',
    PCBOrder: 'PCB订单',
    selectTheOrder: '选择PCB的订单号',
    placedPCBOrders: '已下单PCB订单',
    selectPCBOrder: '请选择PCB订单',
    IBECustomizating: 'IBE定制',
    ownSupply: '自己提供',
    generalInfo: '基本信息',
    chipLayer: '贴片层',
    patchQuantity: '贴片数量',
    chipDevice: '贴片器件',
    plugin: '插件',
    topAndBottom: '顶层、底层全贴',
    topPostingOnly: '只贴顶层',
    bottomLayerOnly: '只贴底层',
    defaultNumber: '默认 PCB 数量 ，最大不允许超过PCB数量',
    Weld: '焊点',
    kinds: '种类',
    PleasenumberOfPatches: '请输入贴片数量',
    PleasesolderJointsForSMDDevices: '请输入贴片器件焊点数量',
    PleasechipDeviceTypes: '请输入贴片器件种类数量',
    PleasepluginSolderJoints: '请输入插件焊点数量',
    PleasepluginTypes: '请输入插件种类数量',
    PleaseselectPatchLayer: '请选择贴片层',
    BOMInfo: 'BOM信息',
    BOMFile: 'BOM文件',
    searchForYourOrderNumber: '搜索您的订单编号',
    orderNo: '订单号',
    Ordertime: '下单时间',
    PCBFilename: 'PCB文件名',
    productCategory: '产品类别',
    orderStatus: '订单状态',
    Cancel: '取消',
    Define: '确定',
    SelectMatedBOM: '选择已配单BOM',
    Compatibility: '匹配度',
    DocumentComparisonAnalysis: '文件比配解析',
    BOMDocument: 'BOM文件',
    UploadTime: '上传时间',
    TimeofDispatch: '配单时间',
    AllModels: '全部型号',
    Numberofmodelsidentified: '已确定型号数',
    Numberofmodelstobedetermined: '待确定型号数',
    Numberofunmatchedmodels: '无匹配型号数',
    MaterialPrice: '物料价格 (IBE) - 单套',
    TheBOMfile: 'BOM 文件共有',
    models: '个型号',
    pleaseidentifythe: '请确认未完全匹配的',
    notmatchexactly: '个型号',
    Reupload: '重新上传',
    ConfirmationofBOMorder: '确认BOM配单',
    awaitingprocessing: '待处理',
    Inprogress: '处理中',
    processingfailure: '处理失败',
    Processingsuccess: '处理成功',
    UploadBOMfiles: '上传BOM文件、智能报价',
    Wewillcalculate: '我们将通过智能搜索，自动匹配计算您的BOM价格',
    SupportExcelfile: '支持Excel文件兼容格式(*.xlsx,*.xls)',
    SelectBOM: '选择BOM',
    yourcomponentkeywords: '搜索您元器件关键词',
    BOMInformation: 'BOM信息',
    Numberofpatches: '贴片数量',
    PreparedComponents: '备料元器件',
    Privatestock: '私有库存',
    IBEorderrequired: '需IBE订货',
    Tobesent: '待邮寄',
    Coordinatefile: '坐标文件',
    Uploadingfiles: '上传文件',
    Uploadcoordinatefile: '上传坐标文件',
    Basicservices: '基础服务',
    Customizedservices: '定制服务',
    PackagingRequirements: '包装要求',
    Expressway: '快递方式',
    Selectingacouriermethod: '选择快递方式',
    Shippingaddress: '收货地址',
    Selectshippingaddress: '选择收货地址',
    DeliveryAddress: '收货地址',
    Addshippingaddress: '添加收货地址',
    Corporation: '公司',
    Individuals: '个人',
    Name: '名字',
    LastName: '姓',
    CountryArea: '国家/地区',
    CityRegion: '城市/区域',
    StreetAddress: '街道地址',
    HouseNumber: '房屋编号',
    MailboxCode: '邮箱编码',
    MobileNumber: '手机号',
    CompanyIdentification: '公司名称',
    EORItaxcode: 'EORI税号',
    Save: '保存',
    Address: '地址',
    Operational: '操作',
    Edit: '编辑',
    Deleting: '删除',
    DefaultAddresses: '默认地址',
    Settingthedefaultaddress: '设置默认地址',
    deletethecurrentaddress: '是否删除当前地址?',
    SmartQuote: '智能报价',
    PCBBilling: 'PCB 计价',
    Laminate: '层板',
    Dend: '套',
    SMTPricing: 'SMT 计价',
    BOMPricing: 'BOM 计价',
    Devices: '种器件',
    Taxonomy: '个总类',
    Taxes: '税费',
    Valueaddedtax: '增值税',
    Aggregate: '合计',
    SubmitOrder: '提交订单',
    Disclaimer: '我已知悉 《免责声明》',
    Fulladdress: '详细地址',
  },
  pcb: {
    PCBfileformatmust:
      'PCB文件格式必须是压缩包形式(如rar,zip);不包含 /; *?"<>|,`等特殊字符；大小不超过50M',
    UploadGerberfiles: '上传Gerber文件、智能报价',
    BasicInfo: '基本信息',
    MaterialType: `
    <h3>板材类型</h3>
    ①如订单需做铝基板，而下单选择的是FR-4材料，做错则不接受投诉，反之一样；
    <br/>
    ②如下单选择FR-4板材，附件备注、或资料内备注或其他形式的说明为铝基板，但下单没有选择铝基板，以下单为准，不接受投诉，反之一样。
    <br/>
    ③热电分离铜基板必须满足：最小钻孔>1.0MM    最小锣槽>1.6MM
    `,
    BoardSizeDesc: `
    <h3>板子尺寸</h3>
    指客户交货板的长宽尺寸。
    <br/>
    ① 如果是单片圆形板交货，长宽尺寸指圆的直径；
    <br/>
    ② 如果是不规则形状交货，长宽尺寸以横竖向最大值计算。
    为了降低成本，不规则板建议第三方拼板出货。
    `,
    BoardQuantityDesc: `
    <h3>板子数量</h3>
    指交货板状态的数量。
  <br/>
  ① 如果是单片交货，按单片数量计算；
  <br/>
  ② 如果是连片交货，则按连片数量计算；
  <br/>
  ③ 下单数量按系统下单可选数量取整数，交货数量以工厂包装出货标示为准。
    `,
    PlateType: '板材类型',
    BoardSize: '板子尺寸',
    ofboards: '板子数量',
    ProductType: '产品类型',
    IndustrialConsumerOtherElectronics: '工业/消费/其他类电子产品',
    Auto: '汽车',
    Healthcare: '医疗',
    PCBProcessInformation: 'PCB工艺信息',
    SMTOptions: 'SMT选项',
    SMTornot: '是否SMT贴片',
    Noneed: '不需要',
    Takeup: '需要',
    Taxes: '税费',
    Notavailable: '暂无',
    Courierfee: '物流费',
    FedEx: '联邦快递',
    Valueadded: '增值税',
    ProcessFee: '工艺费',
    OrderProcessCosts: '订单工艺费用',
    ServiceFee: '服务费',
    Amountofservicefee: '服务费金额',
    DeliveryEstimation: '交期预估',
    pcbonlineordering: 'pcb在线下单',
    pcbfile: 'pcb文件',
    UploadPCBfile: '上传PCB文件',
    Uploadfilesin: '上传文件请使用(.rar .zip ) 格式 ，文件大小 50M 以内',
    pictureis: '3D图仅供参考，具体以实物为准',
    Physicalsimulation: '实物仿真图',
    Fullscreenview: '全屏查看',
    PCBpricing: 'PCB计价',
  },
  bom: {
    BOMQuote: 'BOM 报价',
    UploadBOMfiles: '上传BOM文件、智能报价',
    Wewillcalculateyour: '我们将通过智能搜索，自动匹配计算您的BOM价格',
    SupportExcel: '支持Excel文件兼容格式(*.xlsx,*.xls)',
    DownloadBOMfiletemplate: '下载BOM文件模板',
    Privatestock: '私有库存',
    Prepareinadvance: '提前备货，权益多多',
    Ownmaterial: '自有物料',
    Privatematerial: '私有物料，邮寄使用',
    IBEFeeding: 'IBE 供料',
    Completerange: '种类齐全、价格透明、直接使用',
    BOMQuotationProcess: 'BOM 报价流程',
    Step1: '步骤一',
    Step2: '步骤二',
    Step3: '步骤三',
    Step4: '步骤四',
    UploadBOMfile: '上传BOM文件',
    BOMIntelligent: 'BOM智能匹配/选型',
    STMordering: 'STM 下单/邮寄清单',
    Payment: '支付/邮寄',
    Pleaseselectthenumbe: '请选择贴片数量',
    Otherquantities: '其他数量',
    Pleaseenterthenumber: '请输入贴片数量',
  },
  use: {
    OrderManagement: '订单管理',
    SMTOrder: 'SMT订单',
    PCBOrder: 'PCB订单',
    Privatestock: '私有库存',
    Privateinventorymanagement: '私有库存管理',
    MailingComponents: '邮寄元器件',
    AccountInformation: '账户资料',
    MyProfile: '我的资料',
    ShippingAddress: '收货地址',
    Securitysettings: '安全设置',
  },
  om: {
    OrderDate: '下单日期',
    Startdate: '开始日期',
    Enddate: '结束日期',
    AllOrderTypes: '全部订单类型',
    AllOrderStatus: '全部订单状态',
    Search: '查询',
    OrderNo: '订单编号',
    Timeoforder: '下单时间',
    Postal: '邮寄',
    Estimateddelivery: '预计交期（天）',
    Prices: '价格',
    OrderStatus: '订单状态',
    OrderOperation: '订单操作',
    PCBInfo: 'PCB信息',
    Sheets: '板材',
    BoardSize: '板子尺寸',
    Platelayer: '板子层数',
    ofboards: '板子数量',
    GerberDocument: 'Gerber文件',
    gotothemail: '去邮寄',
    Tobemailed: '待邮寄',
    Progressfollowup: '进度跟进',
    Order: '下单',
    Pay: '付款',
    Audits: '审核',
    Productive: '生产',
    Shipments: '发货',
    Cancel: '取消',
    ViewDetails: '查看详情',
    CancelOrder: '取消订单',
    FactorySupply: '厂家供货',
    Mailed: '已邮寄',
    Inthemail: '邮寄中',
    Receivedgoods: '已收货',
    TopBottom: '顶层+底层',
    Attic: '顶层',
    Bottom: '底层',
    Component: '款元器件',
    chippcb: '片pcb',
    PCBOrder: 'PCB订单',
    OrderInfo: '订单信息',
    PlaceSMTorders: '下SMT订单',
    OrderOperation: '订单操作',
    Layer: '层',
    Chip: '片',
    SerialNo: '序号',
    bompreparation: 'bom备料记录',
    MailingComponents: '邮寄元器件',
    Stockpile: '库存数量',
    Lastupdatedtime: '最后更新时间',
    Valueofgoods: '货值',
    Operate: '操作',
    ViewUpdateLog: '查看更新记录',
    branding: '品牌',
    OriginalModel: '原厂型号',
    ComponentParameters: '元件参数',
    Batches: '批次',
    Datasheet: '数据手册',
    Download: '下载',
    Wewillcalculate: '我们将通过智能搜索，自动匹配计算您的BOM价格',
    SupportExcelfile: '支持Excel文件兼容格式(*.xlsx,*.xls)',
    DownloadBOMfile: '下载BOM文件模板',
    Pleaseentercomponentkeywords: '请输入元器件关键词',
    ExportBOM: '导出BOM',
    Deleting: '删除',
    IBEorderrequired: '需IBE订货',
    Modificationofmaterial: '修改备料',
    UnlockingPrivateInventory: '解锁私有库存',
    BOMMatchingOrderOnlineQuotation: 'BOM配单在线报价',
    BOMfile: 'BOM 文件',
    BOMCode: 'BOM 编码',
    AllModels: '全部型号',
    Determinedmodel: '已确定型号',
    Modeltobedetermined: '待确定型号',
    Nomatchingmodel: '无匹配型号',
    Estimatedpriceperset: '单套预估价格',
    Estimatedtotalprice: '预估总价格',
    Quotationvaliditytime: '报价有效时间',
    All: '全部',
    IBEstocking: 'IBE备货',
    Unmatched: '未匹配',
    Postagerequired: '需邮寄',
    Notsticker: '不贴',
    UsingPrivateStock: '使用私有库存元器件',
    BOMModel: 'BOM型号',
    BOMBit: 'BOM位号',
    Branding: '品牌',
    Componentparameters: '元件参数',
    RecommendedComponents: '推荐元件',
    Dosage: '用量',
    Currentstock: '现有库存',
    IBEPreparation: 'IBE备料元器件',
    Subtotalpurchases: '购买小计',
    Replacementmodel: '更换型号',
    Convertedtomailing: '改为邮寄',
    Compatibility: '匹配度',
    SupplierName: '供应商名称',
    Supplierinternalmodel: '供应商内部型号',
    StandardPackingQuantity: '标准包装量 SPQ',
    Vendorinventory: '供应商库存',
    Perfectmatch: '完全匹配',
    Mismatch: '不匹配',
    Modeltobeconfirmed: '型号待确认',
    Modelandbrand: '型号和品牌待确认',
    Confirmed: '已确认',
    UnmatchedComponents: '未匹配元器件',
    Componentstobemailed: '需邮寄元器件',
    MailingQuantity: '邮寄数量',
    PriceReference: '价格参考',
    UnpostedComponents: '不贴的元器件',
    GoplaceanSMTorder: '去下SMT订单',
    Inthemail: '邮寄中',
    Done: '已完成',
    Cancelled: '已取消',
    Pleaseenterthecouriernumber: '请输入快递单号',
    Mailingtime: '邮寄时间',
    Deliverynote: '快递单号',
    OrderNo: '订单号',
    MailedPCBs: '邮寄PCB',
    Statuses: '状态',
    ComponentInfo: '元件信息',
    Arrivaltime: '到货时间',
    Cancellationdate: '取消时间',
    Numberofdemands: '需求数量',
    AddPackage: '加入包裹',
    addedtothepackage: '',
    Addparcelsuccessfully: '加入包裹成功',
    Minimummailingrequired: '最少需要邮寄',
    packagewillberemoved: '确认将移出包裹吗？？',
    Removingpackages: '移出包裹',
    Prompts: '提示',
    onthecomponentpackage: '是否在元器件包装上元器件编码',
    Yes: '是',
    No: '否',
    Unanimous: '全选',
    ComponentMailingList: '确认元器件邮寄清单',
    addree: '塘头社区第一工业区E栋1层',
    tel: '收件人电话： 1882256040',
    AddresseeAddress: '收件人地址：广东省-珠海市-斗门区乾务镇荔山村先进电子4栋SMT仓6194522号',
    Pleasefillintheaddress: '请严格按照上面地址填写，如因填错导致快递丢失IBE概不负责',
    ExpressName: '快递名称',
    nameofthecourier: '请输入快递名称',
    TrackingNumber: '快递单号',
    deliverynotenumber: '请输入快递单号',
    MailContacts: '邮件联系人',
    Pleaseenteranemailcontact: '请输入邮件联系人',
    MailContactPhone: '邮件联系人电话',
    contactphonenumber: '请输入邮件联系人电话',
    AddCourierNumber: '新增快递单号',
    ConfirmationofComponent: '确认元器件邮寄清单',
    MailingMaterials: '邮寄物料注意事项',
    Mailingmaterialdoes: ' 邮寄物料不等于就会贴装，请务必下SMT订单时选用您邮寄物料库存。',
    Inordertospeedup: '为加快PCB生产，邮寄器件不做检查，IBE按对应的C编号直接入库。',
    outofthewarehouse:
      '在【元件器件准备】出库时，如邮寄数量不够订单需要的数量，接受【有多少贴多少】。',
    Ifthereisanydiscrepancybetween:
      '如有邮寄型号【封装】与【邮寄清单内封装】不符、引脚氧化、器件损坏，接受IBE跳过不焊接或引脚虚焊。',
    MailingDevices: '邮寄器件出库与损耗按IBE标准执行。',
    PCBgenerationstarts: '邮寄器件签收后开始生成PCB。',
    Thedeliverytime: '邮寄器件、PCB签收后开始计算订单交期。',
    Nopickup: '邮寄器件不接受提货。',
    acceptbatteriesbymail: 'IBE不接受邮寄电池。',
    Ihavereadandagree: '我已阅读并同意以上《物料邮寄注意事项》',
    CancelMailing: '取消邮寄',
    Confirmationofmailing: '确认邮寄',
    Interchangeability: '替换',
    Usernickname: '用户昵称',
    Country: '所在国家',
    Cellphonenumber: '手机号',
    Inbox: '邮箱',
    Sector: '行业',
    ConsumerElectronics: '消费电子',
    Telecommunicate: '电信',
    Aerospace: '航天',
    MedicalCare: '医疗',
    CompanyName: '公司名称',
    Save: '保存',
    BillingAddresses: '账单地址',
    Addshippingaddress: '添加收货地址',
    AddBillingAddress: '添加账单地址',
    Names: '姓名',
    Phonenumber: '手机号码',
    Fulladdress: '详细地址',
    currentaddressdeleted: '是否删除当前地址?',
    Loginpassword: '登录密码',
    ResetPassword: '重置密码',
    emailaddress: '电子邮箱',
    ChangeMailbox: '更改邮箱',
    Pleaseenteroldpassword: '请输入旧密码',
    Pleaseenternewpassword: '请输入新密码',
    newpasswordtoconfirm: '请输入确认新密码',
    Submit: '提交',
    Originalpasswordisincorrect: '原密码不正确',
    Passwordchangedsuccessfully: '密码修改成功',
    OldMailboxVerification: '旧邮箱验证',
    GetCAPTCHA: '获取验证码',
    Sentsuccessfully: '发送成功，请查看邮箱',
    Settingupanew: '设置新的电子邮箱',
    Pleaseloginfirst: '请先登录',
    Login: '登录',
    Subscribe: '注册',
    Forgotyourpassword: '忘记密码？',
    Notamember: '还不是会员？',
    SignUpNow: '立即注册',
    Retrievepassword: '找回密码',
    Pleaseenteryouremail: '请输入邮箱',
    Pleaseenterthecode: '请输入验证码',
    Pleaseenteranewpassword: '请输入新密码',
    MemberRegistration: '会员注册',
    Alreadyhaveamember: '已有会员账号？',
    UserServiceAgreement: '阅读并同意《用户服务协议》',
    EmailAccount: '邮箱账号',
    PIN: '密码',
    ConfirmPassword: '确认密码',
    RegisterEmailVerification: '注册邮箱验证',
    RegisterSuccessfully: '注册成功',
    GotoPost: '前往邮寄',
  },

  // 增补翻译
  mall: {
    logout_prompt: '是否退出登录?',
    member_login: '会员登录',
    accept: '接受',
    do_not_accept: '不接受',
    accept_the_batch: '接受批次',
    unlimited: '不限',
    one_year: '1年',
    two_years: '2年',
    three_years: '3年',
    upload_file_support:
      "上传文件仅支持<span style='color: #ff0000;'>XLS、XLSX</span>格式, 请按上传按规则整理的文件，否则会导致数据解析异常。",
    upload_bom_file: '请上传BOM文件',
    file_standard_template: '文件标准样式',
    smt_process: 'SMT工艺',
    based_on_actual_costs: '以实际费用为准',
    layer_board: '{num}层板',
    pcb_pricing: 'PCB 计价',
    estimated_cost: '预估费用',
    final_cost_subject_to_review: '最终费用以实际审核后为准',
    disclaimer: '免责声明',
    read_disclaimer: '我已知悉',
    please_select: '请选择',
    sets: '{num}套',
    process_service: '工艺服务',
    delivery_and_shipping: '交期&发货',
    delivery_time: '交期',
    days: '{num}天',
    express_delivery: '快递',
    shipping_time: '发货时间',
    price_and_costs: '价格费用',
    pcb_cost: 'PCB费用',
    smt_cost: 'SMT费用',
    smt_processing_fee: 'SMT加工费',
    smt_process_service_fee: 'SMT工艺服务费',
    bom_cost: 'BOM物料费用',
    logistics_fee: '物流费',
    tax_fee: '税费',
    quick_match_type_selection: '快速匹配选型',
    quick_match_pros: '优点：无需要等待，快速获取匹配选型',
    quick_match_cons: '缺点：匹配度有一定误差',
    deep_match_type_selection: '深度匹配选型',
    deep_match_pros: '优点：匹配度、准确率较高，误差偏低',
    deep_match_cons: '缺点：时间较久、需要等待',
    length: '长',
    width: '宽',
    next_step: '下一步',
    submit_pcb_order: '提交PCB订单',
    pcb_information: 'PCB信息',
    process_information: '工艺信息',
    shipping_information: '收货信息',
    change_address: '更换地址',
    add_shipping_information: '新增收货信息',
    edit_shipping_information: '修改收货信息',
    add: '新增',
    recipient: '收货人',
    mobile_number: '手机号',
    region: '所在地区',
    shipping_address: '收货地址',
    postal_code: '邮政编码',
    default_address: '是否默认地址',
    cash_on_delivery: '货到付款',
    based_on_actual_cost: '以实际费用为准',
    based_on_actual_time: '以实际时间为准',
    payment_method: '付款方式',
    material_category: '板材类别',
    recipient_name: '收货人姓名',
    enter_recipient_name: '请输入收货人姓名',
    enter_mobile_number: '请输入手机号',
    enter_your_region: '请选择所在地区',
    enter_the_shipping_addres: '请输入收货地址',
    detailed_address_info: '请输入详细地址信息、如街道、门牌号、小区、楼栋号、单元等信息',
    set_as_default_address: '是否默认地址?',
    enter_postal_code: '请输入邮政编码',
    valid_mobile_number: '请输入合法手机号',
    add_address_failed: '添加地址失败，请稍后尝试',
    added_successfully: '添加成功',
    area_code_cannot_be_empty: '区号不能为空',
    edit_address_failed: '编辑地址失败，请稍后尝试',
    set_default_failed: '设置默认失败，请稍后尝试',
    delete_successful: '删除成功',
    enter_smt_quantity: '请输入贴片数量',
    select_an_order: '请选择订单',
    download_bom: '下载BOM',
    proceed_to_smt_order: '去SMT下单',
    reassignment_successful: '重新配单成功',
    pending_confirmation_of_bom: '有待确认BOM，请确认BOM型号后再提交',
    mismatched_bom: '有不匹配BOM，请先更换未匹配的型号，并确认后提交',
    smt_quantity_exceed_pcb: '贴片数量不能大于PCB数量',
    proceed_to_payment: '去支付',
    confirm_bom: 'BOM确认',
    confirm_order: '确认下单',
    previous_step: '上一步',
    model: '型号',
    brand: '品牌',
    package: '封装',
    component_parameters: '元件参数',
    price: '价格',
    inventory: '库存',
    date_code: '批次年限',
    product_link: '产品链接',
    view_details: '查看详情',
    accept_alternative_components: '是否接受替代料',
    select_materials: '请选择物料',
    enter_brand: '输入品牌',
    enter_component_model: '输入元器件型号',
    reset: '重置',
    material_search: '物料查询',
    modification_successful: '修改成功',
    serial_number: '序号',
    add_to_smt: '加入贴片',
    confirmation_successful: '确认成功',
    confirm_change_to_non_smt: '确认将{name}型号改为不贴吗？',
    confirm_add_to_smt: '确认将{name}型号加入贴片？',
    confirm_model: '是否确认{name}型号？',
    confirm_change_to_mail: '确认将{name}型号改为邮寄吗？',
    enter_account_number: '请输入账号',
    enter_password: '请输入密码',
    password_min_length: '密码长度不能少于{num}位',
    captcha_verification: '请完成机器人验证',
    verification_failed: '校验失败',
    payment_successful: '支付成功',
    payment_canceled: '已取消支付',
    back: '返回',
    quote: '报价',
    order: '订单',
    select_courier_company: '请选择快递公司',
    billing_address: '账单地址',
    shipping_information: '邮寄信息',
    smt_quantity: '贴片数量',
    upload_file_limit: '上传文件大小不能超过{num}MB',
    contact_information: '联系方式',
    select_smt_quantity: '请先选择下面的贴片数量',
    testing_services: '测试服务',
    supply_chain_services: '供应链服务',
    after_sales_service: '售后服务',
    logistics_and_fulfillment: '物流与履行',
    oem_odm_services: 'OEM/ODM 服务',
    engineering_design: '工程设计',
    performance: '性能',
    page_not_found: '抱歉，你访问的页面不存在',
    back_to_previous_page: '返回上一页',
    back_to_homepage: '返回首页',
    quantity_cannot_be_empty: '数量不能为空',
    enter_quantity: '请输入数量',
    browser_not_support_video: '您的浏览器不支持视频播放。',
    check_email: '发送成功，请查看邮箱',
    password_retrieval_successful: '找回密码成功',
    enter_login_email_or_username: '请输入登录邮箱或用户名',
    upload_successful: '上传成功',
    delete_successful: '删除成功',
    confirm_removal_from_cart: '确认将{name}移出包裹吗？?',
    component_package_instruction:
      '在寄出的元件包装袋上，您可用任何方式贴上或放入元件编号/元件型号，只要在包装上能看到清晰的编号或元件型号即可。 如未放入，工作人员核对器件会非常麻烦，可能影响订单进度。',
    select_all_first: '请先勾选全选',
    delete_order_confirmation: '将修改...订单信息确定要删除吗',
    confirm_add_all_components_to_cart: '确认将{name}的元器件都加入包裹?',
    invoice: '发票',
    component_information: '元器件信息',
    suggested_quantity: '建议数量',
    shipping_info_update_success: '修改订单快递信息成功',
    operation_failed: '操作失败',
    refund_failed_due_to_association:
      '抱歉，无法退款！由于该订单与订单号{name}的PCB订单形成关联。若要退款，请先把关联的PCB订单退款处理后，才能退款。',
    application_successful: '申请成功，我们将尽快处理',
    place_order: '下单',
    order_settlement: '订单结算',
    permanent_delete_confirmation: '删除的文件无法复原，确认要删除吗?',
    export_file_confirmation: '是否导出该文件?',
    creation_date: '创建日期',
    status: '状态',
    save_successful: '保存成功',
    upload_successful: '上传成功',
    select_placed_pcb_order: '请先选择已下单的PCB订单',
    smt_quantity_exceed_pcb_order: '贴片数量不能大于pcb订单的值',
    bom_file_cannot_be_empty: 'BOM文件不能为空',
    confirm_bom_file: '请先确认BOM文件',
    coordinate_file_cannot_be_empty: '坐标文件不能为空',
    confirm_coordinate_file: '请先确认坐标文件',
    agree_terms_checkbox: '请勾选同意协议',
    switching_product_type_warning: '切换产品类型，原数据将不被保留是否继续?',
    input_value_exceed: '输入值不能大于{num}',
    matching: '匹配中',
    pending_payment: '待付款',
    production_confirmation_pending: '生产确认中',
    in_production: '生产中',
    awaiting_shipment: '待发货',
    awaiting_receipt: '待收货',
    received: '已收货',
    completed: '已完成',
    refunding: '退款中',
    refunded: '已退款',
    cancelled: '已取消',
    deleted: '已删除',
    no_parsing_data: '该文件暂无解析数据',
    select_again: '重新选择',
    order_quantity: '订单数量',
    successfully_changed_to_mail: '改为邮寄成功',
    specification_sheet: '规格书',
    view_order: '查看订单',
    matching_options_method: '匹配选项方式',
    brand_to_be_confirmed: '品牌待确认',
    choose_delivery_address: '请选择收货地址',
    choose_delivery_method: '请选择快递方式',
    yes: '是',
    no: '否',
    component_total: '种元器件，共',
    item_count: '个',
    quantity_exceeds_total: '邮寄数量不能大于总数量',
    confirm_modify_delete_order: '将修改{name}订单信息，确定要删除吗？',
    default: '默认',
    order_successful: '下单成功',
    complete_payment_soon: '请尽快完成支付',
    deep_matching_in_progress: '正在深度匹配中，预计时间：15-30分钟内，请耐心等待！',
    choose_material_type: '请选择板材类型',
    choose_board_layer: '请选择板层',
    enter_board_quantity: '请输入板子数量',
    choose_substitute_material: '请选择是否接受替代料',
    choose_batch: '请选择接受批次',
    bom_number: 'BOM编号',
    name: '名称',
    all_models: '全部型号',
    process_type: '工艺类型',
    view: '查看',
    confirm: '确认',
    package_solder_point_confirmation: '封装焊点确认',
    component_quantity: '元器件数量',
    solder_point_count: '焊点数',
    refund_review_status: '退款审核状态',
    enter_refund_number: '请输入退款单号',
    refund_number: '退款单号',
    application_time: '申请时间',
    order_type: '订单类型',
    refund_amount: '退款金额',
    refund_time: '退款时间',
    refund_reason: '退款原因',
    under_review: '审核中',
    processing_refund: '退款中',
    refunded: '已退款',
    cancelled: '已取消',
    rejected: '已拒绝',
    confirm_cancel: '您确定要取消吗？',
    confirm_delete: '您确定要删除{name}吗？',
    password_change: '密码修改',
    password_change_success: '密码修改成功！请重新登录',
    set_success: '设置成功',
    email_change: '邮箱修改',
    email_change_success: '邮箱修改成功！请重新登录',
    group_package: '组包装',
    cancel_success: '取消成功',
    confirm_cancel_order: '是否取消订单',
    complete: '完成',
    refund: '退款',
    match: '匹配',
    estimated_time: '预计时间：15-30分钟',
    reupload_gerber_file: '重新上传Gerber文件',
    upload_gerber_first: '请先上传Gerber文件',
    payment: '支付',
    production: '生产',
    shipment: '发货',
    receipt: '收货',
    position_number: '位号',
    uploading: '上传中',
    bom_preparation: 'BOM备料',
    refund_records: '退款记录',
    refund: '退款',
    enter_order_number: '请输入订单号',
    modify: '修改',
    contact_phone: '联系电话',
    payment_time: '支付时间',
    order_time: '下单时间',
    order_details: '订单详情',
    all: '全部',
    based_on_review_time: '以审核时间为准',
    to_be_confirmed: '待确认',
    confirmed: '已确认',
    export: '导出',
  },
};
