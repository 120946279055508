export default {
  coommon: {
    login: '登录',
    // ------  表格
    index: '序号',
    operation: '操作',
    // ------  通用
    tips: '提示',
    warning: '警告',
    confirm: '确 定',
    cancel: '取 消',
    close: '关 闭',
    save: '保 存',
    success: '成功',
    Nicknames: ' biệt danh',
    MembershipLevel: 'Cấp thành viên',
  },
  // ------  菜单
  menu: {
    home: 'Trang đầu',
    smartQuote: 'Báo giá thông minh',
    pcb: 'PCB',
    smt: 'SMT',
    bom: 'BOM',
    memberCentre: 'Trung tâm hội viên',
    myOrder: 'Đơn hàng của tôi',
    privateInventory: 'Hàng tồn kho riêng',
    accountInfor: 'Tài liệu tài khoản',
    loginOut: 'Đăng xuất',

    en: 'Tiếng Anh',
    zh: 'Tiếng Trung',
    Vietnamese: 'Tiếng Việt',
    footerText:
      'IBE enables the world’s best brands. Through our unrivalled customer-centric approach, we partner with leading companies in numerous markets to deliver solutions for their most complex challenges.',
    findUs: 'Find Us',
    filing: 'Copyright © IBE Electronics',
    filingRight:
      ' Al Rights Reserved. IBE is a professional PCB manufacturer and PCB assembly supplier in China',
    login: 'Log in',
    subscribe: 'Subscribe',
  },
  home: {
    boardType: 'Loại hình bản',
    boardLayer: 'Số tầng bản',
    more: 'Nhiều hơn',
    boardSize: 'Kích thước bản',
    boardSum: 'Số lượng',
    priceImmediately: 'Tính giá ngay',
    pcbServe: 'Phục vụ PCB',
    multilayerBoard: 'PCB nhiều lớp cao',
    FPCSoftBoard: 'Bảng mềm FPC',
    smtPatch: 'Miếng dán SMT',
    fr: 'FR-4',
    SMDLayer: 'Lớp SMD',
    SMTNumberLayers: 'Số tầng SMT',
    SMDDevices: 'Thiết bị SMD',
    solderJoints: 'Điểm hàn',
    patchType: 'Chủng loại',
    fullSticker: 'Dán đầy đủ ở lớp trên và lớp dưới',
    groundFloor: 'Chỉ dán lớp dưới',
    topLevel: 'Chỉ dán lớp trên cùng',
    aluminumPlate: 'Tấm nhôm',
    tscs: 'Chất nền đồng tách nhiệt điện',
    rogers: 'Bảng tần số cao Rogers',
    pcbServeText:
      'Kiểm tra pcb 1-32 lớp, dịch vụ sản xuất hàng loạt. Việc kiểm tra được vận chuyển trong vòng 24 giờ mà không phải trả phí hỏa tốc. Sử dụng tấm loại A đích thực và tuân thủ mô hình sản xuất tự vận hành của nhà máy. Đảm bảo chất lượng, độ tin cậy và ổn định',
    orderNow: 'Lập tức xuống đơn',
    multilayerBoardSeve: 'Dịch vụ PCB nhiều lớp cao',
    Ply64: 'Bảng 6-32 lớp',
    highBoard: 'Bảng trở kháng có độ chính xác cao',
    multilayerBoardText:
      'Dây chuyền sản xuất chuyên dụng nhiều lớp hoàn toàn mới, bảo vệ hàn dây chuyền sử dụng mạ điện xung LDI, VCP và các sản phẩm đầu tiên trong ngành khác Thiết bị sản xuất tiên tiến, sử dụng bo mạch thương hiệu nổi tiếng, dụng cụ kiểm tra trở kháng chính xác, cung cấp các ký tự độ phân giải cao,Tiêu chuẩn kiểm tra chất lượng cao, dịch vụ đóng gói và vận chuyển cao cấp.',
    FPCSoftBoardServe: 'Dịch vụ bảng mềm FPC',
    supportPanel: 'Hỗ trợ bảng đơn và đôi',
    performance: 'Hiệu suất uốn tốt',
    FPCSoftBoardText:
      'Giá cả tuyệt vời, chất lượng cao, hỗ trợ PI, FR4, thép tấm, băng gia cố hai mặt và màng chắn điện từ. Sử dụng lá đồng điện phân không keo cao cấp, chịu được nhiệt độ cao và có tuổi thọ cao hơn.',
    smtPatchServe: 'Dịch vụ dán SMT',
    placementService: 'Dịch vụ dán PCBA',
    waveSoldering: 'Hàn sóng cắm',
    doubleSidedSticker: 'Nhãn dán hai mặt',
    smtPatchServeText:
      'Dán từ 50 đồng, một mối hàn một đồng, hỗ trợ hàng triệu linh kiện, dây chuyền sản xuất hoàn toàn tự động,để PCB của bạn chỉ cần dán trong nửa ngày là xong.',

    whyChooseUs: 'WHY CHOOSE US',
    whyWork: 'Why More Than 3000 Clients Over the World',
    loveUs: 'Love to Work with Us',
    localService: 'Local Customer Service',
    localServiceText:
      'We have three factories in USA, Vietnam, China respectively to provide prompt pre-sales and after-sales service and technical support.',
    qualityAssurance: 'Quality Assurance',
    qualityAssuranceText:
      'ISO/TS/RoHS/REACH/UL/CE/FDA certificated, our commitment is to never compromise on the safety, compliance and quality of our products and services.',
    promptDelivery: 'Prompt Delivery',
    promptDeliveryText:
      'Two shifts in production department provide you faster delivery time. Quick-turn PCB prototypes can be delivered in 24 hours, PCBA prototypes within 3 days.',
    nonDisclosureAgreement: 'Non-disclosure agreement',
    nonDisclosureAgreementText:
      "We strictly follow the confidential agreements(NDA) to protect customers' interests to keep long term partnership with many renowned customers",
    countriesWorldwide: 'Countries Worldwide',
    countriesWorldwideText: 'USA/South America/EU/Middle East/Asia Area',
    happyCustomers: 'Happy Customers',
    happyCustomersText: 'Leading brands in medical/automotive/new energy field',
    goIbe: 'Giới thiệu về IBE',
    viewVideo: 'Xem video',
    learnMore: 'Tìm hiểu thêm',
    productDescription: 'Mô tả Sản phẩm',
    cameraPCB: 'Camera PCB manufacturing and assembly for monitoring indoor and outdoor security',
    marineShip:
      'Marine ship control system and engine management system circuit board manufacturing company',
    aircraftAccelerometer:
      'Aircraft Accelerometer and Pressure Sensor PCB Manufacturing and Assembly',
    automotiveIntegrated:
      'Automotive Integrated Navigation and Entertainment Stereo System Printed Circuit Board Assembly Company',
    productQuality: 'Đảm bảo chất lượng sản phẩm',
    productQualityText: 'Chứng nhận UL, ISO, đảm bảo chất lượng',
    fastDelivery: 'Đảm bảo giao hàng nhanh',
    fastDeliveryText: 'Giao hàng nhanh theo lô, thời gian giao hàng chỉ 3-4 ngày',
    costPerformance: 'Đảm bảo hiệu suất chi phí cao',
    costPerformanceText: 'Miễn phí vận chuyển toàn quốc, miễn phí kỹ thuật',
    rawMaterials: 'Đảm bảo nguyên liệu',
    rawMaterialsText: 'Được làm bằng vật liệu loại A chất lượng cao',
    contacts: '联系方式',
    testServices: '测试服务',
    supplyChainServices: '供应链服务',
    afterSalesServices: '售后服务',
    logisticsAndFulfillment: '物流与履行',
    ODMSevice: 'OEM/ODM 服务',
    designAndEngineerings: '工程设计',
    capabilities: '性能',
  },
  smt: {
    smtQuote: 'Báo giá SMT',
    smtOrder: 'Đặt hàng SMT',
    SMTOnlineOrdering: 'Đặt hàng trực tuyến SMT',
    PCBInfo: 'Thông tin PCB',
    productType: 'Loại sản phẩm',
    PCBOrder: 'Đơn hàng PCB',
    selectTheOrder: 'Lựa chọn số đơn hàng của PCB',
    placedPCBOrders: 'Đơn hàng PCB  đã xuống',
    selectPCBOrder: 'Hãy lựa chọn đơn hàng PCB',
    IBECustomizating: 'IBE sản xuất',
    ownSupply: 'Tự cung cấp',
    generalInfo: 'Thông tin cơ bản',
    chipLayer: 'Lớp SMD',
    patchQuantity: 'Số lượng miếng dán',
    chipDevice: 'Thiết bị SMD',
    plugin: 'Linh kiện cắm',
    topAndBottom: 'Dán đầy đủ ở lớp trên và lớp dưới',
    topPostingOnly: 'Chỉ dán lớp trên cùng',
    bottomLayerOnly: 'Chỉ dính vào lớp dưới cùng',
    defaultNumber: 'Số lượng PCB mặc định, số lượng PCB tối đa không được phép vượt quá',
    Weld: 'Điểm hàn',
    kinds: 'Chủng loại',
    PleasenumberOfPatches: 'Vui lòng nhập số lượng miếng dán',
    PleasesolderJointsForSMDDevices: 'Vui lòng nhập số lượng điểm hàn của thiết bị dán',
    PleasechipDeviceTypes: 'Vui lòng nhập số lượng chủng loại của thiết bị dán',
    PleasepluginSolderJoints: 'Vui lòng nhập số lượng điểm hàn cắm linh  kiện',
    PleasepluginTypes: 'Vui lòng nhập số lượng linh kiện cắm',
    PleaseselectPatchLayer: 'Vui lòng chọn lớp dán',
    BOMInfo: 'Thông tin BOM',
    BOMFile: 'Tài liệu BOM',
    searchForYourOrderNumber: 'Tìm kiếm mã đơn đặt hàng của bạn',
    orderNo: 'Mã đơn hàng',
    Ordertime: 'Tên tập tin',
    PCBFilename: 'PCB thời gian đặt hàng',
    productCategory: 'Danh mục sản phẩm',
    orderStatus: 'Trạng thái đơn hàng',
    Cancel: 'Hủy bỏ',
    Define: 'Xác nhận',
    SelectMatedBOM: 'Chọn BOM đơn hàng được phân bổ',
    Compatibility: 'Độ phù hợp',
    DocumentComparisonAnalysis: 'Phân tích so sánh tài liệu',
    BOMDocument: 'Tài liệu BOM',
    UploadTime: 'Thời gian tải lên',
    TimeofDispatch: 'Thời gian đặt hàng',
    AllModels: 'Tất cả các mã',
    Numberofmodelsidentified: 'Số lượng mã được xác định',
    Numberofmodelstobedetermined: 'Số lượng mã đợi xác định',
    Numberofunmatchedmodels: 'Số mẫu  không phù hợp',
    MaterialPrice: 'Giá Nguyên Liệu (IBE) - Bộ Đơn',
    TheBOMfile: 'Tài liệu BOM có quy cách',
    models: ' chưa hoàn toàn phù hợp.',
    pleaseidentifythe: 'Vui lòng xác nhận quy cách ',
    notmatchexactly: 'chưa hoàn toàn phù hợp',
    Reupload: 'Truyền lại',
    ConfirmationofBOMorder: 'Xác nhận đơn hàng BOM',
    awaitingprocessing: 'Đợi xử lý',
    Inprogress: 'Đang xử lý',
    processingfailure: 'Xử lý thất bại',
    Processingsuccess: 'Xử lý thành công',
    UploadBOMfiles: 'Tải lên tệp BOM và báo giá thông minh',
    Wewillcalculate:
      'Chúng tôi sẽ tự động khớp và tính giá BOM của bạn thông qua tìm kiếm thông minh',
    SupportExcelfile: 'Hỗ trợ các định dạng tương thích với tệp Excel (*.xlsx, *.xls)',
    SelectBOM: 'Lựa chọn BOM',
    yourcomponentkeywords: 'Tìm kiếm từ khóa thành phần của bạn',
    BOMInformation: 'Thông tin BOM',
    Numberofpatches: 'Số lượng miếng dán',
    PreparedComponents: 'Chuẩn bị linh kiện',
    Privatestock: 'Hàng tồn kho riêng',
    IBEorderrequired: 'Cần đặt hàng từ IBE',
    Tobesent: 'Đợi gửi qua bưu điện',
    Coordinatefile: 'Tài liệu điều phối',
    Uploadingfiles: 'Tải tài liệu lên',
    Uploadcoordinatefile: 'Tải lên tài liệu điều phối',
    Basicservices: 'Dịch vụ cơ bản',
    Customizedservices: 'Dịch vụ tùy chỉnh',
    PackagingRequirements: 'Yêu cầu đóng gói',
    Expressway: 'Phương thức chuyển phát',
    Selectingacouriermethod: 'Lựa chọn phương thức chuyển phát',
    Shippingaddress: 'Địa chỉ nhận hàng',
    Selectshippingaddress: 'Lựa chọn địa chỉ nhận',
    DeliveryAddress: 'Địa chỉ nhận hàng',
    Addshippingaddress: 'Thêm địa chỉ nhận',
    Corporation: 'Công ty',
    Individuals: 'Cá nhân',
    Name: 'Tên',
    LastName: 'Họ',
    CountryArea: 'Quốc gia / khu vực',
    CityRegion: 'Thành phố / khu vực',
    StreetAddress: 'Địa chỉ đường phố',
    HouseNumber: 'Số nhà',
    MailboxCode: 'Mã email',
    MobileNumber: 'Số điện thoại',
    CompanyIdentification: 'Tên Công ty',
    EORItaxcode: 'Mã số thuế EORI',
    Save: 'Lưu',
    Address: 'Địa chỉ',
    Operational: 'Thao tác',
    Edit: 'Chỉnh sửa',
    Deleting: 'Xóa',
    DefaultAddresses: 'Địa chỉ mặc định',
    Settingthedefaultaddress: 'Cài đặt địa chỉ mặc định',
    deletethecurrentaddress: 'Có xóa địa chỉ hiện tại không ',
    SmartQuote: 'Báo giá thông minh',
    PCBBilling: 'Tính giá PCB',
    Laminate: 'Bản lớp',
    Dend: 'Bộ',
    SMTPricing: 'Tính giá SMT',
    BOMPricing: 'Tính giá BOM',
    Devices: 'Thiết bị',
    Taxonomy: 'Thể loại chung',
    Taxes: 'Tiền thuế',
    Valueaddedtax: 'Thuế GTGT',
    Aggregate: 'Tổng cộng',
    SubmitOrder: 'Giao đơn hàng',
    Disclaimer: 'Tôi đã hiểu rõ ',
    Fulladdress: 'Địa chỉ chi tiết',
  },
  pcb: {
    PCBfileformatmust:
      'Định dạng tệp PCB phải ở dạng nén (chẳng hạn như rar, zip); không bao gồm  /; *?"<>|,` và các ký tự đặc biệt khác; kích thước không vượt quá 50M',
    UploadGerberfiles: 'Tải lên tệp Gerber và báo giá thông minh',
    BasicInfo: 'Thông tin cơ bản',
    PlateType: 'Loại hình vật liệu bản',
    BoardSize: 'Kích thước bản',
    ofboards: 'Số lượng bản',
    ProductType: 'Loại hình sản phẩm',
    IndustrialConsumerOtherElectronics: 'Công nghiệp / tiêu dùng / sản phẩm điện tử khác',
    Auto: 'Xe hơi',
    Healthcare: 'Y tế',
    PCBProcessInformation: 'Thông tin công nghệ PCB',
    SMTOptions: 'Chọn hạng mục SMT',
    SMTornot: 'Có dán miếng SMT không ?',
    Noneed: 'Không cần',
    Takeup: 'Cần',
    Taxes: 'Tiền thuế',
    Notavailable: 'Tạm thời không có',
    Courierfee: 'Phí chuyển phát',
    FedEx: 'Vui lòng chọn địa chỉ giao hàng',
    Valueadded: 'Thuế GTGT',
    ProcessFee: 'Phí công nghệ',
    OrderProcessCosts: 'Phí xử lý đơn hàng',
    ServiceFee: 'Phí dịch vụ',
    Amountofservicefee: 'Số tiền phí dịch vụ',
    DeliveryEstimation: 'Ước tính giao hàng',
    pcbonlineordering: 'Đặt hàng trực tuyến pcb',
    pcbfile: 'Tài liệu PCB',
    UploadPCBfile: 'Tải tài liệu PCB lên',
    Uploadfilesin:
      'Vui lòng sử dụng định dạng (.rar .zip) để tải tệp lên và kích thước tệp phải trong khoảng 50M.',
    pictureis: 'Hình ảnh 3D chỉ mang tính chất tham khảo, lấy hình ảnh thực tế làm chuẩn',
    Physicalsimulation: 'Sơ đồ mô phỏng vật lý',
    Fullscreenview: 'Xem toàn màn hình',
  },
  bom: {
    BOMQuote: 'Báo giá BOM',
    UploadBOMfiles: 'Tải lên tệp BOM và báo giá thông minh',
    Wewillcalculateyour:
      'Chúng tôi sẽ tự động khớp và tính giá BOM của bạn thông qua tìm kiếm thông minh',
    SupportExcel: 'Hỗ trợ các định dạng tương thích với tệp Excel (*.xlsx, *.xls)',
    DownloadBOMfiletemplate: 'Tải xuống mẫu tệp BOM',
    Privatestock: 'Tồn kho riêng',
    Prepareinadvance: 'chuẩn bị hàng trước, để có nhiều lợi ích',
    Ownmaterial: 'Nguyên vật liệu tự có',
    Privatematerial: 'Có nguyên vật liệu riêng, gửi hàng qua bưu điện',
    IBEFeeding: 'IBE Cung cấp ',
    Completerange: 'Phạm vi đầy đủ, giá cả minh bạch, sử dụng trực tiếp',
    BOMQuotationProcess: 'Quy trình báo giá BOM',
    Step1: '步骤一',
    Step2: '步骤二',
    Step3: '步骤三',
    Step4: '步骤四',
    UploadBOMfile: 'Tải lên file BOM',
    BOMIntelligent: 'Kết hợp lựa chọn BOM',
    STMordering: 'SMT xuống đơn/ danh sách gửi hàng',
    Payment: 'Thanh toán/ gửi hàng',
    Pleaseselectthenumbe: 'Mời lựa chọn số lượng miếng dán ',
    Otherquantities: 'Số lượng khác',
    Pleaseenterthenumber: 'Mời nhập số lượng miếng dán',
  },
  use: {
    OrderManagement: 'Quản lý đơn đặt hàng',
    SMTOrder: 'Đơn đặt hàng SMT',
    PCBOrder: 'Đơn đặt hàng PCB',
    Privatestock: 'Tồn kho',
    Privateinventorymanagement: 'Quản lý tồn kho',
    MailingComponents: 'Bưu phẩm linh kiện',
    AccountInformation: 'Tài liệu về tài khoản',
    MyProfile: 'Tài liệu của tôi',
    ShippingAddress: 'Địa chỉ nhận hàng',
    Securitysettings: 'Cài đặt an toàn',
  },
  om: {
    OrderDate: 'Ngày xuống đơn',
    Startdate: 'Ngày bắt đầu',
    Enddate: 'Ngày kết thúc',
    AllOrderTypes: 'Toàn bộ loại đơn ',
    AllOrderStatus: 'Toàn bộ tình trạng đơn',
    Search: 'Kiểm tra',
    OrderNo: 'Mã đơn hàng',
    Timeoforder: 'Thời gian xuống đơn',
    Postal: 'Gửi hàng',
    Estimateddelivery: 'Dự kiến thời gian giao hàng (ngày)',
    Prices: 'Giá',
    OrderStatus: 'Trạng thái đơn hàng',
    OrderOperation: 'Thao tác đơn hàng',
    PCBInfo: 'Thông tin PCb',
    Sheets: 'Tấm bản',
    BoardSize: 'Kích thước bản',
    Platelayer: 'Số lớp bảng',
    ofboards: 'Số lượng bảng',
    GerberDocument: 'Tài liệu Gerber',
    gotothemail: 'Đi gửi hàng',
    Tobemailed: 'Đợi gửi hàng',
    Progressfollowup: 'Theo dõi tiến độ',
    Order: 'Xuống đơn',
    Pay: 'Thanh toán',
    Audits: 'Thẩm duyệt',
    Productive: 'Sản xuất',
    Shipments: 'Phát hàng',
    Cancel: 'Hủy',
    ViewDetails: 'Kiểm tra tình trạng',
    CancelOrder: 'Hủy đơn hàng',
    FactorySupply: 'Nhà sản xuất',
    Mailed: 'Đã gửi hàng',
    Inthemail: 'Đang gửi hàng',
    Receivedgoods: 'Đã nhận hàng',
    TopBottom: 'Tầng trên cùng + tầng dưới cùng',
    Attic: 'Tầng trên cùng',
    Bottom: 'Tầng dưới cùng',
    Component: 'Các linh kiện',
    chippcb: 'Tấm pcb',
    PCBOrder: 'Đơn hàng PCB',
    OrderInfo: 'Thông tin đơn hàng',
    PlaceSMTorders: 'Xuống đơn hàng SMT',
    OrderOperation: 'Vận hành đơn hàng',
    Layer: 'Tầng',
    Chip: 'Tấm bản',
    SerialNo: 'Số thứ tự',
    bompreparation: 'Ghi chép chuẩn bị nguyên vật liệu BOM',
    MailingComponents: 'Gửi hàng linh kiện',
    Stockpile: 'Số lượng tồn kho',
    Lastupdatedtime: 'Thời gian update cuối cùng',
    Valueofgoods: 'Giá trị hàng',
    Operate: 'Thao tác',
    ViewUpdateLog: 'Kiểm tra ghi chép mới nhất',
    branding: 'Nhãn hiệu',
    OriginalModel: 'Quy cách nhà máy ban đầu',
    ComponentParameters: 'Tham số linh kiện',
    Batches: 'Lô hàng',
    Datasheet: 'Sổ tay số liệu',
    Download: 'Tải xuống',
    Wewillcalculate:
      'Chúng tôi sẽ thông qua tìm kiếm thông minh, tự động phối hợp tính toán tính BOM của bạn',
    SupportExcelfile: 'Lưu file Excel theo định dạng  (*.xlsx, *.xls)',
    DownloadBOMfile: 'Tải file Bom mẫu',
    Pleaseentercomponentkeywords: 'Mời nhập từ khóa liên quan đến ',
    ExportBOM: 'Xuất BOM',
    Deleting: 'Xóa',
    IBEorderrequired: 'IBE đặt hàng',
    Modificationofmaterial: 'Sửa tài liệu',
    UnlockingPrivateInventory: 'Sửa đổi phân bổ vật liệu',
    BOMMatchingOrderOnlineQuotation: 'báo giá trực tiếp lệnh BOM',
    BOMfile: 'Tài liệu BOM',
    BOMCode: 'Mã BOM',
    AllModels: 'Toàn bộ quy cách',
    Determinedmodel: 'Đã xác định quy cách',
    Modeltobedetermined: 'Chờ xác định quy cách',
    Nomatchingmodel: 'Không có quy cách phù hợp',
    Estimatedpriceperset: 'Giá ước tính bộ đơn',
    Estimatedtotalprice: 'Ước tính tổng tiền',
    Quotationvaliditytime: 'Thời gian hiệu lực của báo giá',
    All: 'Toàn bộ',
    IBEstocking: 'IBE chuẩn bị hàng ',
    Unmatched: 'Không phù hợp',
    Postagerequired: 'Cần gửi hàng',
    Notsticker: 'Không dán',
    UsingPrivateStock: 'Sử dụng linh kiện tồn kho',
    BOMModel: 'Quy cách BOM',
    BOMBit: 'Số BOM',
    Branding: 'Nhãn hiệu',
    Componentparameters: 'Tham số linh kiện',
    RecommendedComponents: 'Đề xuất linh kiện',
    Dosage: 'Lượng dùng',
    Currentstock: 'Tồn kho hiện tại',
    IBEPreparation: 'Chuẩn bị linh kiện cho Ibe',
    Subtotalpurchases: 'Tổng số tiền mua hàng',
    Replacementmodel: 'Thay đổi quy cách',
    Convertedtomailing: 'Sửa thàng gửi hàng qua bưu điện',
    Compatibility: 'Độ phù hợp',
    SupplierName: 'Tên nhà cung ứng',
    Supplierinternalmodel: 'Quy cách nhà cung ứng nội bộ',
    StandardPackingQuantity: 'Lượng tiêu chuẩn đóng gói SPQ',
    Vendorinventory: 'Tồn kho nhà cung ứng',
    Perfectmatch: 'Hoàn toàn phù hợp',
    Mismatch: 'Không phù hợp',
    Modeltobeconfirmed: 'Chờ xác nhận quy cách',
    Modelandbrand: 'Chờ xác nhận quy cách và nhãn hiệu',
    Confirmed: 'Đã xác nhận',
    UnmatchedComponents: 'Những linh kiện chưa phù hợp',
    Componentstobemailed: 'Linh kiện cần gửi hàng',
    MailingQuantity: 'Số lượng vận lượng',
    PriceReference: 'Giá tham khảo',
    UnpostedComponents: 'Linh kiện không dán',
    GoplaceanSMTorder: 'Đi xuống đơn hàng SMT',
    Inthemail: 'Đang gửi hàng',
    Done: 'Đã hoàn thành',
    Cancelled: 'Đã hủy',
    Pleaseenterthecouriernumber: 'Mời nhập mã đơn hàng chuyển phát nhanh',
    Mailingtime: 'Thời gian gửi hàng',
    Deliverynote: 'Mã đơn vận chuyển',
    OrderNo: 'Mã đơn đặt hàng',
    MailedPCBs: 'Gửi hàng PCB',
    Statuses: 'Trạng thái',
    ComponentInfo: 'Thông tin linh kiện',
    Arrivaltime: 'Thời gian hàng tới',
    Cancellationdate: 'Thời gian hủy',
    Numberofdemands: 'Số lượng cần',
    AddPackage: 'Thêm vào bưu kiện',
    addedtothepackage: '',
    Addparcelsuccessfully: 'Thêm vào gói hàng thành công',
    Minimummailingrequired: 'Vận chuyển cần ít nhất…',
    packagewillberemoved: 'Xác nhận chuyển giao bưu kiện chưa？',
    Removingpackages: 'Chuyển giao bưu kiện',
    Prompts: 'Nhắc nhở',
    onthecomponentpackage: 'Đóng gói linh kiện đã dán mã linh kiện chưa',
    Yes: 'Phải',
    No: 'Không',
    Unanimous: 'Chọn toàn bộ',
    ComponentMailingList: 'Xác nhận danh sách linh kiện gửi',
    addree: 'Tầng 1, Tòa nhà E, Khu công nghiệp số 1, Cộng đồng Tangtou',
    tel: 'SĐT người nhận hàng:1882256040',
    AddresseeAddress:
      'Địa chỉ người nhận hàng:Địa chỉ người nhận: Số 6194522, Nhà kho SMT, Tòa nhà 4, Điện tử tiên tiến, Làng Lishan, Thị trấn Qianwu, Quận Doumen, Thành phố Chu Hải, tỉnh Quảng Đông',
    Pleasefillintheaddress:
      'Vui lòng điền địa chỉ đúng theo địa chỉ trên, IBE sẽ không chịu trách nhiệm nếu bưu phẩm bị thất lạc do điền sai.',
    ExpressName: 'Tên đơn hàng',
    nameofthecourier: 'Mời nhập tên đơn hàng',
    TrackingNumber: 'Mã đơn hàng',
    deliverynotenumber: 'Mời nhập mã đơn hàng',
    MailContacts: 'Email  người liên hệ',
    Pleaseenteranemailcontact: 'Mời nhập Email người liên hệ',
    MailContactPhone: 'Email / số điện thoại người liên hệ',
    contactphonenumber: 'Mời nhập Email / số điện thoại người liên hệ',
    AddCourierNumber: 'Tăng thêm mã đơn chuyển phát nhanh',
    ConfirmationofComponent: 'Xác nhận danh sách linh kiện gửi',
    MailingMaterials: 'Gửi nguyên vật liệu cần chú ý những mục sau:',
    Mailingmaterialdoes:
      'Nguyên vật liệu gửi chưa được dán, khi xuống đơn hàng SMT hãy chọn gửi nguyên vật liệu tồn kho',
    Inordertospeedup:
      'Để đẩy nhanh sản xuất PCB,  linh kiện gửi tới sẽ không làm kiểm tra, IBE sẽ dựa theo mã tương ứng để trực tiếp nhập kho',
    outofthewarehouse:
      'Khi xuất kho , nếu số lượng gửi hàng không đủ theo số lượng của đơn đặt hàng, chấp nhận có bao nhiêu thì dán bấy nhiêu',
    Ifthereisanydiscrepancybetween:
      ' Nếu quy cách gửi hàng không khớp với danh sách hàng được gửi, các chân cắm bị oxy hóa hoặc thiết bị bị hỏng, IBE sẽ chấp nhận bỏ qua việc hàn chân cắm .',
    MailingDevices:
      'Giao hàng và thất lạc hàng được gửi qua đường bưu điện sẽ thực hiện theo tiêu chuẩn của IBE',
    PCBgenerationstarts: 'Sau khi linh kiện gửi được ký nhận thì bắt đầu sản xuất thành PCB',
    Thedeliverytime: 'Gửi linh kiện, sạu khi ký nhận PCB thì bắt đầu lập kế hoạch giao hàng',
    Nopickup: 'Gửi hàng không chấp nhận trả hàng',
    acceptbatteriesbymail: 'IBE không chấp nhận  gửi PIN qua đường bưu điện',
    Ihavereadandagree:
      'Tôi đã đọc và đồng ý những hạng mục chú ý khi gửi nguyên vật liệu phía trên',
    CancelMailing: 'Hủy gửi hàng',
    Confirmationofmailing: 'Xác nhận gửi hàng',
    Interchangeability: 'Thay đổi quy cách',
    Usernickname: 'Nick người dùng',
    Country: 'Quốc gia',
    Cellphonenumber: 'Số điện thoại ',
    Inbox: 'Email',
    Sector: 'Ngành nghề',
    ConsumerElectronics: 'Chi phí điện tử',
    Telecommunicate: 'Điện báo',
    Aerospace: 'Hàng không',
    MedicalCare: 'Y tế',
    CompanyName: 'Tên công ty',
    Save: 'Lưu trữ',
    BillingAddresses: 'Địa chỉ hóa đơn',
    Addshippingaddress: 'Thêm địa chỉ nhận hàng',
    AddBillingAddress: 'Thêm địa chỉ trên hóa đơn',
    Names: 'Họ và tên',
    Phonenumber: 'Số điện thoại',
    Fulladdress: 'Địa chỉ chi tiết',
    currentaddressdeleted: 'Có xóa địa chỉ hiện tại không?',
    Loginpassword: 'Mật khẩu đăng nhập',
    ResetPassword: 'Cài lại mật khẩu',
    emailaddress: 'Email',
    ChangeMailbox: 'Thay đổi email',
    Pleaseenteroldpassword: 'Mời nhập mật khẩu cũ',
    Pleaseenternewpassword: 'Mời nhập mật khẩu mới',
    newpasswordtoconfirm: 'Mời xác nhận mật khẩu mới',
    Submit: 'Nộp',
    Originalpasswordisincorrect: 'Mật khẩu cũ không đúng',
    Passwordchangedsuccessfully: 'Thay đổi mật khẩu thành công',
    OldMailboxVerification: 'Xác minh email cũ',
    GetCAPTCHA: 'Nhận mã xác minh',
    Sentsuccessfully: 'Gửi thành công, mời xem email',
    Settingupanew: 'Cài đặt email mới',
    Pleaseloginfirst: 'Mời đăng nhập',
    Login: 'Đăng nhập',
    Subscribe: 'Đăng ký',
    MemberLogin: 'Đăng nhập hội viên',
    Forgotyourpassword: 'Quên mật khẩu',
    Notamember: 'Chưa phải là hội viên',
    SignUpNow: 'Đăng ký ngay',
    Retrievepassword: 'Tìm lại mật khẩu',
    Pleaseenteryouremail: 'Mời nhập Email',
    Pleaseenterthecode: 'Mời nhập mã xác minh',
    Pleaseenteranewpassword: 'Mời nhập mật khẩu mới',
    MemberRegistration: 'Đăng ký hội viên',
    Alreadyhaveamember: 'Có tài khoản hội viên chưa?',
    UserServiceAgreement: 'Đọc và đồng ý với điều khoản người dùng',
    EmailAccount: 'Địa chỉ email',
    PIN: 'Mật khẩu',
    ConfirmPassword: 'Xác nhận mật khẩu',
    RegisterEmailVerification: 'Đăng ký Email xác minh',
    RegisterSuccessfully: 'Đăng ký thành công',
    GotoPost: 'Đi tới gửi hàng',
  },
};
